import { Route, Routes } from 'react-router-dom';
import GuardedRoute from './GuardedRoute';
import Dashboard from "../pages/Dashboard";
import React, { ReactElement } from "react";
import Login from "../pages/Login";
import Main from "../pages/Main";
import Users from "../pages/Users";
import Products from "../pages/Products";
import Orders from "../pages/Orders";
import Categories from "../pages/Categories";
import Invoices from "../pages/Invoices";
import Invoice from "../pages/Invoice";

interface AppRoutesProp {
  /**
   * True, if the user is authenticated, false otherwise.
   */
  isAuthenticated: boolean;
}

const HOME_ROUTE = '/';
const LOGIN_ROUTE = '/login';
// const ABOUT_ROUTE = '/about';

const AppRoutes = (props: AppRoutesProp): ReactElement => {
  const { isAuthenticated } = props;

  return (
    <Routes>
      {/* Unguarded Routes */}
      {/* <Route path={ABOUT_ROUTE} element={<p>About Page</p>} />*/}

      {/* Non-Authenticated Routes: accessible only if user in not authenticated */}
      <Route
        element={
          <GuardedRoute
            isRouteAccessible={!isAuthenticated}
            redirectRoute={HOME_ROUTE}
          />
        }
      >
        {/* Login Route */}
        <Route path="/login" element={<Login />} />
      </Route>

      {/* Authenticated Routes */}
      <Route
        element={
          <GuardedRoute
            isRouteAccessible={isAuthenticated}
            redirectRoute={LOGIN_ROUTE}
          />
        }
      >
        <Route path="/" element={<Main />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/products" element={<Products />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/invoices" element={<Invoices />} />
        </Route>
        <Route path="/invoice/:id" element={<Invoice />} />
      </Route>

      {/* Not found Route */}
      <Route path="*" element={<p>Page Not Found</p>} />
    </Routes>
  );
};

export default AppRoutes;
