import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Card, Drawer, FormControl, Tooltip } from "@rewind-ui/core";
import { ArrowLineRight, Check, Pencil, Trash } from "@phosphor-icons/react";
import CreateInvoiceProductModal from "./CreateInvoiceProductModal";
import { Invoice, InvoiceCreate, InvoicePaymentCreate, InvoiceProductCreate } from "../../types/Invoice";
import CreateInvoicePaymentModal from "./CreateInvoicePaymentModal";

export const CreateInvoiceDrawer = (
  props: {
    showDrawer: boolean,
    onShowDrawer: (value: boolean) => void,
    onSaveChanges: (invoice: InvoiceCreate, isEdit: boolean) => void,
    editInvoice?: Invoice | null,
  }) => {
  const { showDrawer, onShowDrawer, onSaveChanges, editInvoice } = props;
  const [Id, setId] = useState<number | null>(null);
  const [customerBusiness, setCustomerBusiness] = useState('');
  const [customerRFC, setCustomerRFC] = useState('');
  const [customerCountry, setCustomerCountry] = useState('');
  const [customerState, setCustomerState] = useState('');
  const [customerCity, setCustomerCity] = useState('');
  const [customerStreet, setCustomerStreet] = useState('');
  const [customerZip, setCustomerZip] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [shipName, setShipName] = useState('');
  const [shipBusiness, setShipBusiness] = useState('');
  const [shipCountry, setShipCountry] = useState('');
  const [shipCity, setShipCity] = useState('');
  const [shipRFC, setShipRFC] = useState('');
  const [shipState, setShipState] = useState('');
  const [shipStreet, setShipStreet] = useState('');
  const [shipZip, setShipZip] = useState('');
  const [shipPhone, setShipPhone] = useState('');
  const [shippingType, setShippingType] = useState('');
  const [shippingPlace, setShippingPlace] = useState('');
  const [shippingCost, setShippingCost] = useState('0');
  const [date, setDate] = useState('');
  const [showProductModal, setShowProductModal] = useState(false);
  const [productList, setProductList] = useState<InvoiceProductCreate[]>([]);
  const [editProduct, setEditProduct] = useState<InvoiceProductCreate | null>(null);
  const [editProductIndex, setEditProductIndex] = useState<number | null>(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentList, setPaymentList] = useState<InvoicePaymentCreate[]>([]);
  const [editPayment, setEditPayment] = useState<InvoicePaymentCreate | null>(null);
  const [editPaymentIndex, setEditPaymentIndex] = useState<number | null>(null);


  useEffect(() => {
    if (editInvoice) {
      setId(editInvoice.id);
      setCustomerBusiness(editInvoice.customerBusiness);
      setCustomerRFC(editInvoice.customerRFC);
      setCustomerCountry(editInvoice.customerCountry);
      setCustomerState(editInvoice.customerState);
      setCustomerCity(editInvoice.customerCity);
      setCustomerStreet(editInvoice.customerStreet);
      setCustomerZip(editInvoice.customerZip);
      setCustomerPhone(editInvoice.customerPhone);
      setShipName(editInvoice.shipName);
      setShipBusiness(editInvoice.shipBusiness);
      setShipCountry(editInvoice.shipCountry);
      setShipCity(editInvoice.shipCity);
      setShipRFC(editInvoice.shipRFC);
      setShipState(editInvoice.shipState);
      setShipStreet(editInvoice.shipStreet);
      setShipZip(editInvoice.shipZip);
      setShipPhone(editInvoice.shipPhone);
      setDate(new Date(editInvoice.dateOrder).toISOString().split('T')[0]);
      setShippingType(editInvoice.shippingType);
      setShippingPlace(editInvoice.shippingPlace);
      setShippingCost(editInvoice.shippingCost.toString());
      setProductList([...editInvoice.invoiceProducts]);
      setPaymentList([...editInvoice.invoicePayments]);
      return;
    }
    setId(null);
    setCustomerBusiness('');
    setCustomerRFC('');
    setCustomerCountry('');
    setCustomerState('');
    setCustomerCity('');
    setCustomerStreet('');
    setCustomerZip('');
    setCustomerPhone('');
    setShipName('');
    setShipBusiness('');
    setShipCountry('');
    setShipCity('');
    setShipRFC('');
    setShipState('');
    setShipStreet('');
    setShipZip('');
    setShipPhone('');
    setDate('');
    setShippingType('');
    setShippingPlace('');
    setShippingCost('0');
    setProductList([]);
    setPaymentList([]);
  }, [showDrawer]);

  const handlePaymentModal = (value: boolean) => setShowPaymentModal(value);
  const handleProductModal = (value: boolean) => setShowProductModal(value);
  const handleCustomerBusiness = (event: ChangeEvent<HTMLInputElement>) => setCustomerBusiness(event.target.value);
  const handleDrawerShow = (value: boolean) => onShowDrawer(value);
  const handleDate = (event: ChangeEvent<HTMLDataElement>) => setDate(event.target.value);
  const handleCustomerRFC = (event: ChangeEvent<HTMLInputElement>) => setCustomerRFC(event.target.value);
  const handleCustomerCountry = (event: ChangeEvent<HTMLInputElement>) => setCustomerCountry(event.target.value);
  const handleCustomerState = (event: ChangeEvent<HTMLInputElement>) => setCustomerState(event.target.value);
  const handleCustomerCity = (event: ChangeEvent<HTMLInputElement>) => setCustomerCity(event.target.value);
  const handleCustomerStreet = (event: ChangeEvent<HTMLInputElement>) => setCustomerStreet(event.target.value);
  const handleCustomerZip = (event: ChangeEvent<HTMLInputElement>) => setCustomerZip(event.target.value);
  const handleCustomerPhone = (event: ChangeEvent<HTMLInputElement>) => setCustomerPhone(event.target.value);
  const handleShipName = (event: ChangeEvent<HTMLInputElement>) => setShipName(event.target.value);
  const handleShipBusiness = (event: ChangeEvent<HTMLInputElement>) => setShipBusiness(event.target.value);
  const handleShipRFC = (event: ChangeEvent<HTMLInputElement>) => setShipRFC(event.target.value);
  const handleShipCountry = (event: ChangeEvent<HTMLInputElement>) => setShipCountry(event.target.value);
  const handleShipState = (event: ChangeEvent<HTMLInputElement>) => setShipState(event.target.value);
  const handleShipCity = (event: ChangeEvent<HTMLInputElement>) => setShipCity(event.target.value);
  const handleShipStreet = (event: ChangeEvent<HTMLInputElement>) => setShipStreet(event.target.value);
  const handleShipZip = (event: ChangeEvent<HTMLInputElement>) => setShipZip(event.target.value);
  const handleShipPhone = (event: ChangeEvent<HTMLInputElement>) => setShipPhone(event.target.value);
  const handleShippingType = (event: ChangeEvent<HTMLInputElement>) => setShippingType(event.target.value);
  const handleShippingPlace = (event: ChangeEvent<HTMLInputElement>) => setShippingPlace(event.target.value);
  const handleShippingCost = (event: ChangeEvent<HTMLInputElement>) => setShippingCost(event.target.value);


  const handleSaveChanges = () => {
    const invoice: InvoiceCreate = {
      customerBusiness,
      customerRFC,
      customerCountry,
      customerState,
      customerCity,
      customerStreet,
      customerZip,
      customerPhone,
      shipBusiness,
      shipName,
      shipRFC,
      shipCountry,
      shipState,
      shipCity,
      shipStreet,
      shipZip,
      shipPhone,
      dateOrder: new Date(date),
      shippingInsurance: 0,
      shippingType,
      shippingPlace,
      shippingCost: Number(shippingCost),
      invoiceProducts: productList,
      invoicePayments: paymentList,
    }
    if (editInvoice) {
      Object.assign(invoice, { id: editInvoice.id });
    }
    onSaveChanges(invoice, !!editInvoice);
  }

  const handleNewProductModal = () => {
    setEditProduct(null);
    setShowProductModal(true);
  }

  const handleEditProductModal = (product: InvoiceProductCreate, index: number) => {
    setEditProduct(product);
    setEditProductIndex(index);
    handleProductModal(true);
  }

  const handleAddProduct = (product: InvoiceProductCreate) => {
    setProductList([...productList, product]);
    handleProductModal(false);
  }

  const handleEditProduct = (product: InvoiceProductCreate, index: number) => {
    const array = [...productList];
    array[index] = product;

    setProductList(array);
    handleProductModal(false);
  }

  const handleRemoveProduct = (index: number) => {
    const array = [...productList];
    if (index !== -1) {
      array.splice(index, 1);
      setProductList(array);
    }
  }

  const handleNewPaymentModal = () => {
    setEditPayment(null);
    setShowPaymentModal(true);
  }

  const handleEditPaymentModal = (payment: InvoicePaymentCreate, index: number) => {
    setEditPayment(payment);
    setEditPaymentIndex(index);
    handlePaymentModal(true);
  }

  const handleAddPayment = (payment: InvoicePaymentCreate) => {
    setPaymentList([...paymentList, payment]);
    handlePaymentModal(false);
  }

  const handleEditPayment = (payment: InvoicePaymentCreate, index: number) => {
    const array = [...paymentList];
    array[index] = payment;

    setPaymentList(array);
    handlePaymentModal(false);
  }

  const handleRemovePayment = (index: number) => {
    const array = [...paymentList];

    if (index !== -1) {
      array.splice(index, 1);
      setPaymentList(array);
    }
  }

  const formatPrice = (price: number) => {
    return price.toLocaleString('es-MX', {
      style: 'decimal',
      currency: 'MXN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    });
  }

  return (
    <div>
      <Drawer open={showDrawer} onClose={() => { handleDrawerShow(false) }} className="drawer-floating" closeOnEscape={false} overlayCloseOnClick={false}>
        <Card className="w-full" bordered={false} shadow="none">
          <Card.Header className="bg-gray-50 sticky top-0 z-[50]">
            <div className="flex items-center justify-between w-full">
              <Tooltip label="Close" size="xs">
                <button onClick={() => handleDrawerShow(false)}>
                  <ArrowLineRight
                    size={20} weight="bold"
                    className="text-gray hover:text-gray-500 transition-colors"
                  />
                </button>
              </Tooltip>
              <button
                className="button-primary rounded-md flex items-center gap-x-2 text-sm"
                disabled={
                  !customerBusiness.length || !customerCountry.length || !customerState.length || !customerCity.length ||
                  !date.length || !productList.length || !shippingType.length || !shippingPlace.length || !shippingCost.length
                }
                onClick={handleSaveChanges}
              >
                <Check size={18} weight="bold" />
                Save changes
              </button>
            </div>
          </Card.Header>

          <Card.Body className="flex flex-1">
            <div className="flex flex-col justify-between">
              <div className="flex flex-col md:w-[30rem] mx-auto">
                <h3 className="text-md text-gray-700 font-semibold">Create invoice</h3>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm" >
                    Customer Business:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={customerBusiness}
                    onChange={handleCustomerBusiness}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm">
                    Customer RFC:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={customerRFC}
                    onChange={handleCustomerRFC}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm" >
                    Customer Country:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={customerCountry}
                    onChange={handleCustomerCountry}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm" >
                    Customer State:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={customerState}
                    onChange={handleCustomerState}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm" >
                    Customer City:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={customerCity}
                    onChange={handleCustomerCity}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm">
                    Customer Street:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={customerStreet}
                    onChange={handleCustomerStreet}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm">
                    Customer ZIP Code:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={customerZip}
                    onChange={handleCustomerZip}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm">
                    Customer Phone:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={customerPhone}
                    onChange={handleCustomerPhone}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm">
                    Shipping Name:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={shipName}
                    onChange={handleShipName}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm">
                    Shipping Business:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={shipBusiness}
                    onChange={handleShipBusiness}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm">
                    Shipping RFC:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={shipRFC}
                    onChange={handleShipRFC}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm">
                    Shipping Country:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={shipCountry}
                    onChange={handleShipCountry}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm">
                    Shipping State:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={shipState}
                    onChange={handleShipState}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm">
                    Shipping City:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={shipCity}
                    onChange={handleShipCity}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm">
                    Shipping Street:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={shipStreet}
                    onChange={handleShipStreet}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm">
                    Shipping ZIP Code:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={shipZip}
                    onChange={handleShipZip}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm">
                    Shipping Phone:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={shipPhone}
                    onChange={handleShipPhone}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <div className="flex items-center justify-between">
                    <FormControl.Label className="text-sm" >
                      Order Date:
                    </FormControl.Label>
                  </div>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="date"
                    className="text-sm bg-white focus:bg-white"
                    value={date}
                    onChange={handleDate}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm" >
                    Shipping Type:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={shippingType}
                    onChange={handleShippingType}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm" >
                    Shipping Place:
                  </FormControl.Label>
                  <FormControl.Input
                    withRing={false}
                    tone="solid"
                    type="text"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={shippingPlace}
                    onChange={handleShippingPlace}
                  />
                </FormControl>
                <FormControl className="mt-8">
                  <FormControl.Label className="text-sm" >
                    Shipping Cost:
                  </FormControl.Label>
                  <FormControl.Input

                    withRing={false}
                    tone="solid"
                    type="number"
                    className="text-sm bg-white focus:bg-white"
                    autoComplete="off"
                    value={shippingCost}
                    onChange={handleShippingCost}
                  />
                </FormControl>
                <div>
                  <FormControl className="mt-8">
                    <FormControl.Label className="text-sm" >
                      Products:
                    </FormControl.Label>
                    <div className="flex flex-col gap-y-2 pt-2">
                      {productList.map((product, idx) => (
                        <div key={`product-item-${idx}`} className="flex gap-x-4 items-center">
                          <p className="flex-1 text-sm truncate">{product.name}</p>
                          <p className="mr-4 text-sm">{product.quantity} x ${formatPrice(product.price)}</p>
                          <Button
                            disabled={showPaymentModal || showProductModal}
                            size="sm"
                            icon
                            variant="tertiary"
                            className="hover:bg-black/10"
                            onClick={() => handleEditProductModal(product, idx)}>
                            <Pencil />
                          </Button>
                          <Button
                            disabled={showPaymentModal || showProductModal}
                            size="sm"
                            icon
                            variant="tertiary"
                            className="hover:bg-black/10"
                            onClick={() => handleRemoveProduct(idx)}>
                            <Trash />
                          </Button>
                        </div>
                      ))}
                    </div>
                  </FormControl>
                  <Button
                    onClick={handleNewProductModal}
                    disabled={showPaymentModal || showProductModal}
                    className="mt-3 button-primary hover:bg-primary-dark focus:bg-primary-dark active:bg-primary-dark rounded w-full">
                    Add new product
                  </Button>
                </div>

                <div>
                  <FormControl className="mt-8">
                    <FormControl.Label className="text-sm">
                      Payments:
                    </FormControl.Label>
                    <div className="flex flex-col gap-y-2 pt-2">
                      {paymentList.map((payment, idx) => (
                        <div key={`product-item-${idx}`} className="flex gap-x-4 items-center">
                          <p className="flex-1 text-sm truncate">{new Date(payment.time).toISOString()}</p>
                          <p className="mr-4 text-sm">{payment.paymentType}</p>
                          <p className="mr-4 text-sm">${formatPrice(payment.amount)}</p>
                          <Button
                            disabled={showPaymentModal || showProductModal}
                            size="sm"
                            icon
                            variant="tertiary"
                            className="hover:bg-black/10"
                            onClick={() => handleEditPaymentModal(payment, idx)}>
                            <Pencil />
                          </Button>
                          <Button
                            disabled={showPaymentModal || showProductModal}
                            size="sm"
                            icon
                            variant="tertiary"
                            className="hover:bg-black/10"
                            onClick={() => handleRemovePayment(idx)}>
                            <Trash />
                          </Button>
                        </div>
                      ))}
                    </div>
                  </FormControl>
                  <Button
                    onClick={handleNewPaymentModal}
                    disabled={showPaymentModal || showProductModal}
                    className="mt-3 button-primary hover:bg-primary-dark focus:bg-primary-dark active:bg-primary-dark rounded w-full">
                    Add new payment
                  </Button>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Drawer>
      <CreateInvoiceProductModal
        editIndex={editProductIndex}
        editProduct={editProduct}
        showModal={showProductModal}
        onShowModal={handleProductModal}
        onSaveChanges={handleAddProduct}
        onSaveEdit={handleEditProduct}
      />
      <CreateInvoicePaymentModal
        editIndex={editPaymentIndex}
        editPayment={editPayment}
        showModal={showPaymentModal}
        onShowModal={handlePaymentModal}
        onSaveChanges={handleAddPayment}
        onSaveEdit={handleEditPayment}
      />
    </div>
  )
}
