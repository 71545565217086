import { gql } from '@apollo/client';

export const GET_PRODUCTS_QUERY = gql`
    query Products {
        products {
            id
            name
            price
            description
            acquisitionType
            images {
                filename
                id
            }
            coverImage {
                id
                filename
            }
            category {
                id
                name
            }
            createdAt
            updatedAt
        }
    }
`;

export const CREATE_PRODUCT_MUTATION = gql`
    mutation CreateProduct($createProductInput: CreateProductInput!) {
        createProduct(createProductInput: $createProductInput) {
            id
            name
            price
            description
            acquisitionType
            images {
                filename
                id
            }
            coverImage {
                id
                filename
            }
            category {
                id
                name
            }
            createdAt
            updatedAt
        }
    }
`;

export const UPDATE_PRODUCT_MUTATION = gql`
    mutation UpdateProduct($updateProductInput: UpdateProductInput!) {
    updateProduct(updateProductInput: $updateProductInput) {
        name
        price
        stock
        acquisitionType
        coverImage {
            filename
            id
        }
        images {
            filename
        }
    }
}
`;

export const REMOVE_PRODUCT_MUTATION = gql`
    mutation RemoveProduct($removeProductId: String!) {
    removeProduct(id: $removeProductId) {
        success
        id
    }
}
`;