import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/layout/Navbar";
import Sidebar from "../components/layout/Sidebar";

const Main = () => {
  const mainContentRef = React.useRef(null);

  return (
    <div className="flex">
      {/* Fix to rewind, this includes some classes */}
      <div
        className="opacity-25
        opacity-75
        h-[18px]
        w-[17px]
        w-[calc(100vw-6rem)]
        sm:w-[18.75rem]
        md:w-[31.25rem]
        lg:w-[50rem]
        gap-y-1
        py-1.5
        px-2
        hidden"
      />
      <Sidebar />
      <div className="flex flex-col w-full bg-neutral-50 h-screen overflow-y-hidden">
        <div ref={mainContentRef} className="h-screen overflow-y-auto scroll-smooth">
          <div className="sticky top-0 bg-opacity-50 backdrop-blur z-[50] transition-colors bg-white">
            <Navbar />
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Main;
