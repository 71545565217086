import { useCallback } from 'react';
import { useNavigate } from "react-router-dom";

export const useFormatPrice = (price: number) => price.toLocaleString('es-MX', {
  style: 'decimal',
  currency: 'MXN',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: true,
});
