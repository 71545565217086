import React, { useEffect } from 'react';
import { useQuery } from "@apollo/client";
import { DotsThree, Pencil, PlusCircle, Trash } from "@phosphor-icons/react";
import { Button, Dropdown, Table } from "@rewind-ui/core";
import { GET_ORDERS_QUERY } from "../hooks/Order/useOrder";
import { OrdersResponse } from "../types/Order";

const Orders = () => {
  const { data, refetch } = useQuery<OrdersResponse>(GET_ORDERS_QUERY);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <main className="flex flex-col flex-1 overflow-y-scroll mt-7 px-12">
        <div className="flex items-center justify-between">
          <div>
            <p className="font-semibold text-3xl">Orders</p>
            <p className="text-gray text-sm mt-2">List of orders placed and delivery status</p>
          </div>
        </div>
        <section className="w-full mt-8 bg-zinc-100 p-4 rounded-2xl">
          <Table striped={false} hoverable={true} headerColor="white" className="rounded-2xl">
            <Table.Thead>
              <Table.Tr>
                <Table.Th align="left" className="p-[16px]">ID</Table.Th>
                <Table.Th align="left" className="p-[16px]">Order status</Table.Th>
                <Table.Th align="left" className="p-[16px]">Payment type</Table.Th>
                <Table.Th align="left" className="p-[16px]">Payment status</Table.Th>
                <Table.Th align="left" className="p-[16px]">Total</Table.Th>
                <Table.Th align="left" className="p-[16px]">Created date</Table.Th>
                <Table.Th align="right" className="p-[16px]"></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data?.orders.map((order) => (
                <Table.Tr
                  key={`contact-${order.id}`}
                  className="cursor-pointer"
                >
                  <Table.Td>
                    <p className="text-sm font-medium">{order.id}</p>
                  </Table.Td>
                  <Table.Td>
                    <p className="text-sm font-medium">{order.status}</p>
                  </Table.Td>
                  <Table.Td>
                    <p className="text-sm font-medium">{order.paymentType}</p>
                  </Table.Td>
                  <Table.Td>
                    <p className="text-sm font-medium">--</p>
                  </Table.Td>
                  <Table.Td>
                    <p className="text-sm font-medium">${order.total}</p>
                  </Table.Td>
                  <Table.Td>
                    <p className="text-sm font-medium">{(new Date(order.createdAt)).toDateString()}</p>
                  </Table.Td>
                  <Table.Td>
                    <Dropdown itemColor="gray" withChevron={false}>
                      <Dropdown.Trigger>
                        <Button size="md" tone="transparent" withRing={false} icon={true} className="hover:bg-black/5">
                          <DotsThree size={24} weight="bold" />
                        </Button>
                      </Dropdown.Trigger>
                      <Dropdown.Content>
                        <Dropdown.Label>Options</Dropdown.Label>
                        <Dropdown.Divider />
                        <Dropdown.Item color="gray">
                          <Pencil size={20} className="mr-1.5" />
                          Edit product
                        </Dropdown.Item>
                        <Dropdown.Item color="red">
                          <Trash size={20} className="mr-1.5" />
                          Delete product
                        </Dropdown.Item>
                      </Dropdown.Content>
                    </Dropdown>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </section>
      </main>
    </div>
  );
}

export default Orders;
