import { gql } from "@apollo/client";

export const GET_ORDERS_QUERY = gql`
    query Orders {
        orders {
            id
            paymentType
            status
            total
            total
            createdAt
        }
    }
`;

// export const GET_FUNNEL_QUERY = gql`
//     query Funnel($funnelId: ID!) {
//         funnel(id: $funnelId) {
//             id
//             name
//             stages {
//                 id
//                 name
//                 stageContacts {
//                     id
//                     position
//                     contact {
//                         id
//                         email
//                         company
//                         name
//                         phone
//                         source
//                     }
//                 }
//             }
//         }
//     }
// `;
