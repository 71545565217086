import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from "@apollo/client";
import { DotsThree, Pencil, PlusCircle, Trash } from "@phosphor-icons/react";
import { Accordion, Button, Dropdown, Table, ToastContainer, useToast } from "@rewind-ui/core";
import { CategoriesResponse, CategoryCreate, CategoryCreateResponse } from "../types/Category";
import { CREATE_CATEGORY_MUTATION, GET_CATEGORIES_QUERY } from "../hooks/Category/useCategory";
import { CreateCategoryDrawer } from "../components/categories/CreateCategoryDrawer";

const Categories = () => {
  const toast = useToast();
  const { data, refetch } = useQuery<CategoriesResponse>(GET_CATEGORIES_QUERY);
  const [createCategoryMutation] = useMutation<CategoryCreateResponse>(CREATE_CATEGORY_MUTATION);
  const [showDrawer, setShowDrawer] = useState(false);

  const onShowDrawer = () => {
    setShowDrawer(false);
  }

  const onSaveChanges = async (category: CategoryCreate) => {
    try {
      await createCategoryMutation({
        variables: {
          createCategoryInput: category,
        }
      });

      await refetch();
      onShowDrawer();

      toast.add({
        id: 'category-created-success',
        color: 'blue',
        description: 'Category created successfully',
      });
    } catch (error) {
      console.error(error);

      toast.add({
        id: 'category-created-failed',
        color: 'red',
        description: 'An error occurred while trying to create the category',
      });
    }
  }

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <main className="flex flex-col flex-1 overflow-y-scroll mt-7 px-12">
        <div className="flex items-center justify-between">
          <div>
            <p className="font-semibold text-3xl">Categories</p>
            <p className="text-gray text-sm mt-2">Manage categories for products</p>
          </div>
          <div>
            <button
              className="button-primary hover:bg-primary/80 focus:bg-primary/80"
              onClick={() => setShowDrawer(true)}
            >
              <PlusCircle size={20} weight="fill" />
              <span className="ml-2">Add new category</span>
            </button>
          </div>
        </div>
        <section className="w-full mt-8 bg-zinc-100 p-4 rounded-2xl">
          <Table striped={false} hoverable={true} headerColor="white" className="rounded-2xl">
            <Table.Thead>
              <Table.Tr>
                <Table.Th align="left" className="p-[16px]">Category name</Table.Th>
                <Table.Th align="left" className="p-[16px]">Category parent</Table.Th>
                <Table.Th align="left" className="p-[16px]">Created date</Table.Th>
                <Table.Th align="right" className="p-[16px]"></Table.Th>
              </Table.Tr>
            </Table.Thead>
          </Table>

          <div>
            {data?.categories.filter((category) => !category.parentCategory).map((category, index) => (
              <Accordion
                className="rounded-none"
                activeColor="white" bordered={false}
                shadow="base"
                shadowColor="gray"
                key={`accordion-category-${index}`}>
                <Accordion.Item anchor={`item-${category.id}-${index}`}>
                  <Accordion.Header>
                    <p className="text-sm font-semibold text-gray-700">{category.name}</p>
                  </Accordion.Header>
                  <Accordion.Body>
                    {data?.categories.find((c) => category.id === c.parentCategory?.id) ?
                      <Table>
                        <Table.Tbody>
                          {data?.categories.filter((subcategory) => subcategory.parentCategory?.id === category.id).map((category) => (
                            <Table.Tr
                              key={`contact-${category.id}`}
                              className="cursor-pointer"
                            >
                              <Table.Td>
                                <p className="text-sm">{category.name}</p>
                              </Table.Td>
                              <Table.Td>
                                <p className="text-sm font-medium">{category.parentCategory?.name ?? '--'}</p>
                              </Table.Td>
                              <Table.Td>
                                <p className="text-sm font-medium">{(new Date(category.createdAt)).toDateString()}</p>
                              </Table.Td>
                              <Table.Td>
                                <Dropdown itemColor="gray" withChevron={false}>
                                  <Dropdown.Trigger>
                                    <Button
                                      size="md"
                                      tone="transparent"
                                      withRing={false}
                                      icon={true}
                                      className="hover:bg-black/5">
                                      <DotsThree size={24} weight="bold" />
                                    </Button>
                                  </Dropdown.Trigger>
                                  <Dropdown.Content>
                                    <Dropdown.Label>Options</Dropdown.Label>
                                    <Dropdown.Divider />
                                    <Dropdown.Item color="gray">
                                      <Pencil size={20} className="mr-1.5" />
                                      Edit category
                                    </Dropdown.Item>
                                    <Dropdown.Item color="red">
                                      <Trash size={20} className="mr-1.5" />
                                      Delete category
                                    </Dropdown.Item>
                                  </Dropdown.Content>
                                </Dropdown>
                              </Table.Td>
                            </Table.Tr>
                          ))}
                        </Table.Tbody>
                      </Table>
                      :
                      <p className="text-sm text-gray-500">No subcategories</p>
                    }
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
          </div>
        </section>
      </main>

      <CreateCategoryDrawer showDrawer={showDrawer} onShowDrawer={onShowDrawer} onSaveChanges={onSaveChanges}
        categories={data?.categories} />
      <ToastContainer />
    </div>
  );
}

export default Categories;
