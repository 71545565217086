import React, { useEffect, useState } from "react";
import { Card, Combobox, Drawer, FormControl, Tooltip } from "@rewind-ui/core";
import { ArrowLineRight, Check } from "@phosphor-icons/react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Image, Product, ProductCreate } from "../../types/Product";
import Dropzone from "./Dropzone";
import { useQuery } from "@apollo/client";
import { CategoriesResponse } from "../../types/Category";
import { GET_CATEGORIES_QUERY } from "../../hooks/Category/useCategory";

type Inputs = {
  name: string,
  description: string,
  price: string,
  acquisitionType: string[],
  categoryId: string,
  images: File[],
};

export const CreateProductDrawer = (
  props: {
    showDrawer: boolean,
    onShowDrawer: (value: boolean) => void,
    onSaveChanges: (product: ProductCreate, coverImage: number | null, isEdit: boolean) => void,
    editProduct?: Product | null,
  }) => {
  const { showDrawer, onShowDrawer, onSaveChanges, editProduct } = props;
  const { register, control, reset, handleSubmit, formState: { errors } } = useForm<Inputs>();
  const { data: categories, refetch } = useQuery<CategoriesResponse>(GET_CATEGORIES_QUERY);
  const [coverImage, setCoverImage] = useState<null | number>(null);
  const handleDrawerShow = (value: boolean) => onShowDrawer(value);

  const onSubmit: SubmitHandler<Inputs> = data => {
    if (editProduct) {
      Object.assign(data, { id: editProduct.id });
    }
    onSaveChanges({ ...data }, coverImage, !!editProduct);
  };

  useEffect(() => {
    if (showDrawer && !editProduct) {
      refetch();
      reset({
        name: "",
        description: "",
        price: "0",
        acquisitionType: [],
        categoryId: "",
        images: [],
      });

      setCoverImage(null);
      return;
    }
    getEditProducts();
  }, [showDrawer]);

  const getEditProducts = async () => {
    if (editProduct) {

      editProduct.images.map((image, idx) => editProduct.coverImage.id === image.id && setCoverImage(idx));
      reset({
        name: editProduct.name,
        description: editProduct.description,
        price: editProduct.price,
        acquisitionType: editProduct.acquisitionType,
        categoryId: editProduct.category?.id,
        images: await filesPromises(editProduct.images.map((image) => image)),
      });

    }
  }

  async function filesPromises(files: Image[]) {
    const filesPromises: Promise<File>[] = files.map(async (img: Image) => {
      const url: string = `${process.env.REACT_APP_PUBLISH_STATIC_URI}/store/images/` + img.filename;
      const response = await fetch(url);
      const data = await response.blob();
      const metadata = {
        type: data.type,
      };

      const file = new File([data], img.filename, metadata);
      return Object.assign(file, { path: img.filename, preview: URL.createObjectURL(data) });
    });

    const images: File[] = await Promise.all(filesPromises);
    return images;
  }

  return (
    <div>
      <Drawer open={showDrawer} onClose={() => handleDrawerShow(false)} className="drawer-floating" closeOnEscape={false} overlayCloseOnClick={false}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="w-full" bordered={false} shadow="none">
            <Card.Header className="bg-gray-50 sticky top-0 z-[50]">
              <div className="flex items-center justify-between w-full">
                <Tooltip label="Close" size="xs">
                  <button
                    type="button"
                    onClick={() => handleDrawerShow(false)}
                  >
                    <ArrowLineRight
                      size={20} weight="bold"
                      className="text-gray hover:text-gray-500 transition-colors"
                    />
                  </button>
                </Tooltip>
                <button
                  className="button-primary rounded-md flex items-center gap-x-2 text-sm"
                  disabled={false}
                  type="submit"
                >
                  <Check size={18} weight="bold" />
                  Save changes
                </button>
              </div>
            </Card.Header>

            <Card.Body className="flex flex-1">
              <div className="flex flex-col justify-between">
                <div className="flex flex-col md:w-[30rem] mx-auto">
                  <h3 className="text-md text-gray-700 font-semibold">Create product</h3>

                  <FormControl className="mt-8" validation={errors.name?.type === 'required' ? 'invalid' : 'none'}>
                    <FormControl.Label className="text-sm" required>
                      Product Name:
                    </FormControl.Label>
                    <FormControl.Input
                      required
                      withRing={false}
                      tone="solid"
                      type="text"
                      className="text-sm bg-white focus:bg-white"
                      autoComplete="off"
                      defaultValue={""}
                      {...register("name", { required: true })}
                      aria-invalid={errors.name ? "true" : "false"}
                    />
                    {errors.name?.type === 'required' && <FormControl.Text className="text-red-500 text-xs">Product name is required</FormControl.Text>}
                  </FormControl>

                  <FormControl className="mt-8">
                    <FormControl.Label className="text-sm">
                      Description:
                    </FormControl.Label>
                    <FormControl.Input
                      withRing={false}
                      tone="solid"
                      type="text"
                      className="text-sm bg-white focus:bg-white"
                      autoComplete="off"
                      defaultValue={""}
                      {...register("description")}
                    />
                  </FormControl>

                  <FormControl className="mt-8" validation={errors.price?.type === 'required' ? 'invalid' : 'none'}>
                    <FormControl.Label className="text-sm" required>
                      Price:
                    </FormControl.Label>
                    <FormControl.Input
                      required
                      withRing={false}
                      tone="solid"
                      type="number"
                      className="text-sm bg-white focus:bg-white"
                      autoComplete="off"
                      min={0}
                      step={0.01}
                      defaultValue={"0"}
                      {...register("price", { required: true })}
                    />
                    {errors.price?.type === 'required' && <FormControl.Text className="text-red-500 text-xs">Price is required</FormControl.Text>}
                  </FormControl>

                  <FormControl className="mt-8" validation={errors.acquisitionType?.type === 'required' ? 'invalid' : 'none'}>
                    <FormControl.Label className="text-sm" required>
                      Acquisition Type:
                    </FormControl.Label>
                    <Controller
                      name="acquisitionType"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Combobox
                          multiple={true}
                          closeOnSelect={false}
                          defaultValue={[]}
                          value={field.value}
                          onChange={field.onChange}
                        >
                          <Combobox.Option value="sale" label="Sale" />
                          <Combobox.Option value="rent" label="Rent" />
                        </Combobox>
                      )}
                    />
                    {errors.acquisitionType?.type === 'required' && <FormControl.Text className="text-red-500 text-xs">Acquisition type is required</FormControl.Text>}
                  </FormControl>

                  <FormControl className="mt-8">
                    <FormControl.Label className="text-sm">
                      Category:
                    </FormControl.Label>
                    <Controller
                      name="categoryId"
                      control={control}
                      render={({ field }) => (
                        <Combobox
                          defaultValue={undefined}
                          value={field.value}
                          onChange={field.onChange}
                        >
                          {categories && categories.categories &&
                            categories.categories.map((category, idx) => (
                              <Combobox.Option key={`product-category-${idx}`} value={category.id} label={category.name} />
                            ))
                          }
                        </Combobox>
                      )}
                    />
                  </FormControl>

                  <FormControl className="mt-8">
                    <FormControl.Label className="text-sm" required>
                      Images:
                    </FormControl.Label>
                    <Controller
                      name="images"
                      control={control}
                      defaultValue={[]}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Dropzone images={field.value} onChange={field.onChange} onChangeCover={setCoverImage} />
                      )}
                    />
                    {errors.images?.type === 'required' && <FormControl.Text className="text-red-500 text-xs">Images are required</FormControl.Text>}
                  </FormControl>
                </div>
              </div>
            </Card.Body>
          </Card>
        </form>
      </Drawer>
    </div>
  )
}
