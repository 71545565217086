import React, { useEffect, useState } from "react";
import AppRoutes from "./routes/AppRoutes";
import useAuthenticated from "./hooks/Auth/useAuth";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const isAuth = useAuthenticated();

  useEffect(() => {
    setIsAuthenticated(isAuth);
  }, [isAuth])

  if (isAuthenticated === null) {
    return (
      <p>Loading...</p>
    );
  }

  return (
    <AppRoutes isAuthenticated={isAuthenticated} />
  );
};
export default App;
