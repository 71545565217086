import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
    mutation Signin($loginInput: LoginInput!) {
        signin(loginInput: $loginInput) {
            token
            user {
                id
                email
                fullName
                isActive
                roles
            }
        }
    }
`;

export const REVALIDATE_QUERY = gql`
    query Revalidate {
        revalidate {
            token
            user {
                id
                email
                fullName
                isActive
                roles
            }
        }
    }
`;
