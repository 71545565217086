import React, { useEffect, useState } from 'react';
import { Button, FormControl, InputGroup, InputValidation } from "@rewind-ui/core";
import { Envelope, Eye, EyeSlash, Key } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import "../assets/scss/auth.scss";

const API_URL = process.env.REACT_APP_API_URI;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [inputValidation, setInputValidation] = useState<InputValidation>('none');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setInputValidation('none');
  }, [email, password]);

  const handleAuth = () => {
    handleLogin();
  }

  const handleLogin = async () => {
    try {
      setIsLoading(true);

      const response = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data) {
          localStorage.setItem('token', data.token);
          localStorage.setItem('user', JSON.stringify(data.user));

          navigate('/');
        }
      } else {
        setInputValidation('invalid');
        console.error('Error en la solicitud:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex">
      <main className="flex w-full h-screen">
        <section className="w-full lg:w-5/12 px-12 flex items-center justify-center">
          <div className="w-[400px]">
            <p className="text-3xl font-semibold text-center mb-2">Welcome Back</p>
            <p className="text-sm text-zinc-400 text-center mb-8">Welcome back, please enter your details</p>

            <div className="mt-8 flex flex-col">
              <div>
                <FormControl>
                  <FormControl.Label className="text-sm" required>
                    Email address
                  </FormControl.Label>
                  <InputGroup>
                    <InputGroup.Text className="rounded-xl"><Envelope className="text-gray-500" /></InputGroup.Text>
                    <FormControl.Input
                      autoFocus
                      type="email"
                      size="lg"
                      placeholder="Email address"
                      value={email}
                      validation={inputValidation}
                      className="text-sm rounded-xl"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
              </div>

              <div>
                <FormControl className="mt-4">
                  <FormControl.Label className="text-sm" required>
                    Password
                  </FormControl.Label>
                  <InputGroup>
                    <InputGroup.Text className="rounded-xl"><Key className="text-gray-500" /></InputGroup.Text>
                    <FormControl.Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Password"
                      size="lg"
                      value={password}
                      validation={inputValidation}
                      rightIcon={
                        <span
                          className="relative"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <EyeSlash /> : <Eye />}
                        </span>
                      }
                      className="text-sm rounded-xl"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
              </div>

              <Button
                type="submit"
                className="bg-primary text-white rounded-2xl py-6 mt-4 text-sm"
                variant="tertiary"
                loading={isLoading}
                onClick={handleAuth}
              >
                Continue
              </Button>
            </div>
          </div>
        </section>
        <section className="hidden lg:block w-7/12 bg-slate-100">

        </section>
      </main>
    </div>
  );
}

export default Login;
