import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from "@apollo/client";
import { DotsThree, Pencil, PlusCircle, Trash } from "@phosphor-icons/react";
import { Button, Card, Dropdown, Modal, Table, ToastContainer, useToast } from "@rewind-ui/core";
import { CREATE_PRODUCT_MUTATION, GET_PRODUCTS_QUERY, REMOVE_PRODUCT_MUTATION, UPDATE_PRODUCT_MUTATION } from "../hooks/Product/useProduct";
import { Product, ProductCreate, ProductCreateResponse, ProductRemove, ProductUpdate, ProductUpdateResponse, ProductsResponse } from "../types/Product";
import { CreateProductDrawer } from "../components/products/CreateProductDrawer";

const Users = () => {
  const toast = useToast();
  const [showDrawer, setShowDrawer] = useState(false);
  const [productSelected, setProductSelected] = useState<Product | null>(null)
  const { data, refetch } = useQuery<ProductsResponse>(GET_PRODUCTS_QUERY);
  const [removeProductMutation] = useMutation<ProductRemove>(REMOVE_PRODUCT_MUTATION);
  const [createProductMutation] = useMutation<ProductCreateResponse>(CREATE_PRODUCT_MUTATION);
  const [updateProductMutation] = useMutation<ProductUpdateResponse>(UPDATE_PRODUCT_MUTATION);
  const [productId, setProductId] = useState<string | null>(null)
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    refetch();
  }, []);

  const onShowDrawer = () => {
    setShowDrawer(false);
  }

  const removeProduct = async (id: string | null) => {
    try {
      await removeProductMutation({
        variables: {
          removeProductId: id,
        }
      });
      await refetch();
      setShowModal(!showModal);

      toast.add({
        id: 'product-removed-success',
        color: 'blue',
        description: 'Product removed successfully'
      });

    } catch (error) {
      console.error(error);

      toast.add({
        id: 'product-removed-failed',
        color: 'red',
        description: 'An error occurred while trying to remove the product'
      });
    }
  }

  const updateProduct = async (product: ProductUpdate, coverImage: number | null) => {
    await updateProductMutation({
      variables: {
        updateProductInput: {
          ...product,
          price: Number(product.price),
          coverIndex: coverImage,
        },
      }
    });
  }

  const onSaveChanges = async (product: ProductUpdate | ProductCreate, coverImage: number | null, isEdit: boolean) => {
    if (coverImage === null) {
      toast.add({
        id: 'product-created-failed',
        color: 'red',
        description: 'Selecting a default cover image is required',
      });

      return;
    }

    if (isEdit && productSelected) {
      try {
        await updateProduct(product as ProductUpdate, coverImage);
        await refetch();
        onShowDrawer();

        toast.add({
          id: 'product-updated-success',
          color: 'blue',
          description: 'Product updated successfully',
        });

      } catch (error) {
        console.error(error);

        toast.add({
          id: 'product-updated-failed',
          color: 'red',
          description: 'An error occurred while trying to update the product',
        });
      }
      return;
    }

    try {
      await createProductMutation({
        variables: {
          createProductInput: {
            ...product,
            price: Number(product.price),
            coverIndex: coverImage,
          },
        }
      });

      await refetch();
      onShowDrawer();

      toast.add({
        id: 'product-created-success',
        color: 'blue',
        description: 'Product created successfully',
      });
    } catch (error) {
      console.error(error);

      toast.add({
        id: 'product-created-failed',
        color: 'red',
        description: 'An error occurred while trying to create the product',
      });
    }
  }

  return (
    <div>
      <main className="flex flex-col flex-1 overflow-y-scroll mt-7 px-12">
        <div className="flex items-center justify-between">
          <div>
            <p className="font-semibold text-3xl">Products</p>
            <p className="text-gray text-sm mt-2">Catalog of all registered products</p>
          </div>
          <div>
            <button
              className="button-primary hover:bg-primary/80 focus:bg-primary/80"
              onClick={() => {
                setProductSelected(null);
                setShowDrawer(true)
              }}
            >
              <PlusCircle size={20} weight="fill" />
              <span className="ml-2">Add new product</span>
            </button>
          </div>
        </div>
        <section className="w-full mt-8 bg-zinc-100 p-4 rounded-2xl">
          <Table striped={false} hoverable={true} headerColor="white" className="rounded-2xl">
            <Table.Thead>
              <Table.Tr>
                <Table.Th align="left" className="p-[16px]">Image</Table.Th>
                <Table.Th align="left" className="p-[16px]">Product name</Table.Th>
                <Table.Th align="left" className="p-[16px]">Price</Table.Th>
                <Table.Th align="left" className="p-[16px]">Description</Table.Th>
                <Table.Th align="left" className="p-[16px]">Category</Table.Th>
                <Table.Th align="left" className="p-[16px]">Created date</Table.Th>
                <Table.Th align="right" className="p-[16px]"></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data?.products.map((product) => (
                <Table.Tr
                  key={`contact-${product.id}`}
                  className="cursor-pointer"
                >
                  <Table.Td>
                    {product.coverImage &&
                      <img
                        className="h-24 w-24 rounded object-cover"
                        src={`${process.env.REACT_APP_PUBLISH_STATIC_URI}/store/images/${product.coverImage.filename}`}
                        alt={product.name}
                      />
                    }
                  </Table.Td>
                  <Table.Td>
                    <p className="text-sm font-medium">{product.name}</p>
                  </Table.Td>
                  <Table.Td>
                    <p className="text-sm font-medium">${product.price}</p>
                  </Table.Td>
                  <Table.Td>
                    <p className="text-sm font-medium">{product.description.substring(0, 90)}...</p>
                  </Table.Td>
                  <Table.Td>
                    {product.category ? product.category.name : '--'}
                  </Table.Td>
                  <Table.Td>
                    <p className="text-sm font-medium">{(new Date(product.createdAt)).toDateString()}</p>
                  </Table.Td>
                  <Table.Td>
                    <Dropdown itemColor="gray" withChevron={false} onClick={() => setProductId(product.id)}>
                      <Dropdown.Trigger>
                        <Button size="md" tone="transparent" withRing={false} icon={true} className="hover:bg-black/5">
                          <DotsThree size={24} weight="bold" />
                        </Button>
                      </Dropdown.Trigger>
                      <Dropdown.Content>
                        <Dropdown.Label>Options</Dropdown.Label>
                        <Dropdown.Divider />
                        <Dropdown.Item color="gray" onClick={() => {
                          setProductSelected(product);
                          setShowDrawer(true);
                        }}>
                          <Pencil size={20} className="mr-1.5" />
                          Edit product
                        </Dropdown.Item>
                        <Dropdown.Item color="red" onClick={() => setShowModal(!showModal)}>
                          <Trash size={20} className="mr-1.5" />
                          Delete product
                        </Dropdown.Item>
                      </Dropdown.Content>
                    </Dropdown>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </section>
      </main>

      <CreateProductDrawer showDrawer={showDrawer} onShowDrawer={onShowDrawer} onSaveChanges={onSaveChanges} editProduct={productSelected} />
      <ToastContainer />

      <Modal size='md' position='center' open={showModal} onClose={() => null}>
        <Card className="w-full">
          <Card.Header>
            <h3>Delete product</h3>
          </Card.Header>
          <Card.Body>
            <p className='text-sm'>Are you sure you want to delete this product?</p>
          </Card.Body>
          <Card.Footer className="bg-gray-50/50 justify-end space-x-2">
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button className="bg-red-400 hover:bg-red-500" onClick={() => removeProduct(productId)}>Delete</Button>
          </Card.Footer>
        </Card>
      </Modal>

    </div>
  );
}

export default Users;
