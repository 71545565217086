import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Card, FormControl, Modal } from "@rewind-ui/core";
import { X } from "@phosphor-icons/react";
import { InvoiceProductCreate, InvoiceProductUpdate } from "../../types/Invoice";

const CreateInvoiceProductModal = (

  props: {
    editProduct: InvoiceProductCreate | null,
    editIndex: number | null,
    showModal: boolean,
    onShowModal: (value: boolean) => void,
    onSaveChanges: (product: InvoiceProductCreate) => void,
    onSaveEdit: (product: InvoiceProductCreate, index: number) => void,
  }) => {
  const { editProduct, editIndex, showModal, onShowModal, onSaveChanges, onSaveEdit } = props;

  const [name, setName] = useState('');
  const [imei, setImei] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const handleName = (event: ChangeEvent<HTMLInputElement>) => setName(event.target.value);
  const handleImei = (event: ChangeEvent<HTMLInputElement>) => setImei(event.target.value);
  const handlePrice = (event: ChangeEvent<HTMLInputElement>) => setPrice(event.target.value);

  const handleQuantity = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const maxLength = 6;

    if (inputValue.length <= maxLength) {
      setQuantity(inputValue);
    }
  };

  const handleSaveChanges = () => {
    const product = {
      name,
      imei,
      price: Number(price),
      quantity: parseInt(quantity),
    }

    if (editProduct !== null) {
      Object.assign(product, { id: editProduct.id });
    }

    if (editProduct !== null && editIndex !== null) {
      onSaveEdit(product, editIndex);
      return;
    }
    onSaveChanges(product);
  }

  useEffect(() => {
    if (showModal) {
      if (editProduct) {
        setName(editProduct.name);
        setImei(editProduct.imei);
        setPrice(editProduct.price.toString());
        setQuantity(editProduct.quantity.toString());
        return;
      }
      setName('');
      setImei('');
      setPrice('');
      setQuantity('');
    }
  }, [showModal]);


  return (
    <>
      <Modal open={showModal} size="md" onClose={() => onShowModal(false)} closeOnEscape={false} overlayCloseOnClick={false} position="center" className="lg:mr-[520px]">
        <Card size="sm" className="w-full">
          <Card.Header
            className="bg-gray-50/50 font-medium"
            actions={
              <Button onClick={() => onShowModal(false)} size="xs" color="gray" icon={true}>
                <X />
              </Button>
            }
          >
            {editProduct ? 'Edit' : 'Create'} product
          </Card.Header>
          <Card.Body className="space-y-3">
            <FormControl size="sm">
              <FormControl.Label required>Product name</FormControl.Label>
              <FormControl.Input type="text" tone="solid" placeholder="Enter the product name..." autoComplete="off" value={name} onChange={handleName} />
            </FormControl>

            <FormControl size="sm">
              <FormControl.Label>IMEI</FormControl.Label>
              <FormControl.Input type="text" tone="solid" placeholder="Enter the IMEI..." autoComplete="off" value={imei} onChange={handleImei} />
            </FormControl>

            <FormControl size="sm">
              <FormControl.Label required>Price</FormControl.Label>
              <FormControl.Input type="number" tone="solid" placeholder="Enter the price..." autoComplete="off" value={price} onChange={handlePrice} />
            </FormControl>

            <FormControl size="sm">
              <FormControl.Label required>Quantity</FormControl.Label>
              <FormControl.Input type="number" tone="solid" placeholder="Enter the quantity..." autoComplete="off" value={quantity} onChange={handleQuantity} />
            </FormControl>
          </Card.Body>
          <Card.Footer className="bg-gray-50/50 justify-end space-x-2">
            <Button onClick={() => onShowModal(false)} size="sm" tone="transparent" color="gray">
              Cancel
            </Button>
            <Button
              className="font-semibold"
              onClick={handleSaveChanges}
              size="sm"
              color="blue"
              tone="light"
              disabled={!name.length || !price.length || !quantity.length}
            >
              {editProduct ? 'Edit product' : 'Add product'}
            </Button>
          </Card.Footer>
        </Card>
      </Modal>
    </>
  );
}

export default CreateInvoiceProductModal;
