import React, { useEffect } from 'react';
import { useQuery } from "@apollo/client";
import { DotsThree, EnvelopeSimple, Pencil, Phone, PlusCircle, Trash } from "@phosphor-icons/react";
import { Avatar, Badge, Button, Dropdown, Table } from "@rewind-ui/core";
import { GET_USERS_QUERY } from "../hooks/User/useUser";
import { UserResponse } from "../types/User";

const Users = () => {
  const { data, refetch } = useQuery<UserResponse>(GET_USERS_QUERY);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <main className="flex flex-col flex-1 overflow-y-scroll mt-7 px-12">
        <div className="flex items-center justify-between">
          <div>
            <p className="font-semibold text-3xl">Users</p>
            <p className="text-gray text-sm mt-2">List of registered clients and administrators.</p>
          </div>
          <div>
            <button className="button-primary hover:bg-primary/80 focus:bg-primary/80">
              <PlusCircle size={20} weight="fill" />
              <span className="ml-2">Add new user</span>
            </button>
          </div>
        </div>
        <section className="w-full mt-8 bg-zinc-100 p-4 rounded-2xl">
          <Table striped={false} hoverable={true} headerColor="white" className="rounded-2xl">
            <Table.Thead>
              <Table.Tr>
                <Table.Th align="left" className="p-[16px]">Full name</Table.Th>
                <Table.Th align="left" className="p-[16px]">Email</Table.Th>
                <Table.Th align="left" className="p-[16px]">Type</Table.Th>
                <Table.Th align="left" className="p-[16px]">Registration date</Table.Th>
                <Table.Th align="right" className="p-[16px]"></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data?.users.map((user) => (
                <Table.Tr
                  key={`contact-${user.id}`}
                  className="cursor-pointer"
                >
                  <Table.Td>
                    <div className="flex items-center gap-x-4 pl-[8px]">
                      <Avatar color="blue" size="lg" tone="glossy" initials={user.fullName.split(" ").map((n)=>n[0]).join("")} />
                      <div>
                        <p className="text-sm font-medium">{user.fullName}</p>
                      </div>
                    </div>
                  </Table.Td>
                  <Table.Td>
                    <p className="text-sm flex items-center gap-x-2"><EnvelopeSimple /> {user.email}</p>
                  </Table.Td>
                  <Table.Td>
                    <Badge color="red" tone="light" className="px-2 capitalize">
                      {user.roles[user.roles.length - 1]}
                    </Badge>
                  </Table.Td>
                  <Table.Td>
                    {(new Date(user.createdAt)).toDateString()}
                  </Table.Td>
                  <Table.Td>
                    <Dropdown itemColor="gray" withChevron={false}>
                      <Dropdown.Trigger>
                        <Button size="md" tone="transparent" withRing={false} icon={true} className="hover:bg-black/5">
                          <DotsThree size={24} weight="bold" />
                        </Button>
                      </Dropdown.Trigger>
                      <Dropdown.Content>
                        <Dropdown.Label>Options</Dropdown.Label>
                        <Dropdown.Divider />
                        <Dropdown.Item color="gray">
                          <Pencil size={20} className="mr-1.5" />
                          Edit user
                        </Dropdown.Item>
                        <Dropdown.Item color="red">
                          <Trash size={20} className="mr-1.5" />
                          Delete user
                        </Dropdown.Item>
                      </Dropdown.Content>
                    </Dropdown>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </section>
      </main>
    </div>
  );
}

export default Users;
