import { useCallback } from 'react';
import { useNavigate } from "react-router-dom";

export const useLogout = () => {
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    localStorage.clear();
    navigate('/login');
  }, []);

  return handleLogout;
};
