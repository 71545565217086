import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Bag, ListDashes, Package, Receipt, SignOut, Users } from "@phosphor-icons/react";
import { Badge } from "@rewind-ui/core";
import { useLogout } from "../../hooks/Utils/useLogout";
import Logo from "../../assets/images/logo.png";

const mainMenu = [
  { name: 'Dashboard', icon: 'dashboard', link: '/' },
  { name: 'Orders', icon: <Package size={20} />, link: '/orders' },
  { name: 'Products', icon: <Bag size={20} />, link: '/products' },
  { name: 'Categories', icon: <ListDashes size={20} />, link: '/categories' },
  { name: 'Users', icon: <Users size={20} />, link: '/users' },
];

const settingsMenu = [
  { name: 'Invoices', icon: <Receipt size={20} />, link: '/invoices' },
];

const Sidebar = () => {
  const location = useLocation();
  const handleLogout = useLogout();

  return (
    <header
      className="flex flex-col justify-between px-6 pt-7 w-[330px] border-r border-r-gray-100 dark:bg-primary-dark
      h-screen bg-white dark:rounded-tr-3xl overflow-y-auto"
    >
      <div>
        <div className="flex items-center justify-center gap-x-4 pb-2">
          <div>
            <img className="w-[192px] relative -left-3" src={Logo} alt="Funnels Logo" />
          </div>
        </div>

        <div className="mt-8">
          <p className="text-xs pl-6 text-gray font-semibold">MAIN MENU</p>
          <ul className="flex flex-col gap-y-4 mt-4 text-gray dark:text-gray-200">
            {mainMenu.map((item, idx) => (
              <Link
                to={item.link} key={`nav-${idx}`}
                className={`flex flex-row gap-x-5 items-center dark:gap-y-2 px-6 py-4 rounded-full
                ${location.pathname === item.link ? 'text-primary bg-primary/10 font-semibold' : ''}
                hover:text-primary transition-colors`}
              >
                {typeof item.icon === 'string' ?
                  <i className={`icon icon-${item.icon}`} style={{ fontSize: '16px' }} />
                  : item.icon
                }
                <span className="text-sm">{item.name}</span>
                {item.name === 'Chat' &&
                  <Badge
                    radius="full" size="xs" className="font-normal text-[11px] bg-red-100 text-red-400 px-2"
                  >New!</Badge>
                }
              </Link>
            ))}
          </ul>
        </div>
        <div className="mt-12">
          <p className="text-xs pl-6 text-gray font-semibold">OTHERS</p>
          <ul className="flex flex-col gap-y-4 mt-4 text-gray dark:text-gray-200">
            {settingsMenu.map((item, idx) => (
              <Link
                to={item.link} key={`nav-${idx}`}
                className={`flex flex-row gap-x-5 items-center dark:gap-y-2 px-6 py-4 rounded-full
                ${location.pathname === item.link ? 'text-primary bg-primary-light font-semibold' : ''} 
                hover:text-primary transition-colors`}
              >
                {typeof item.icon === 'string' ?
                  <i className={`icon icon-${item.icon}`} style={{ fontSize: '16px' }} />
                  : item.icon
                }
                <span className="text-sm">{item.name}</span>
              </Link>
            ))}
            <span
              onClick={handleLogout}
              className="flex flex-row gap-x-5 items-center dark:gap-y-2 px-6 py-4 rounded-full
              hover:text-primary transition-colors cursor-pointer"
            >
              <SignOut size={20} />
              <span className="text-sm">Logout</span>
            </span>
          </ul>
        </div>
      </div>
      <div className="pl-6 pb-6 flex flex-col gap-y-2">
        <p className="text-xs text-gray font-medium">Global SDN Logistics</p>
        <p className="text-xs text-gray">© 2023 All Rights Reserved</p>
      </div>
    </header>
  );
};
export default Sidebar;
