import { useEffect, useState } from 'react';
import { decodeJwt } from "jose";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { REVALIDATE_QUERY } from "./useLogin";
import { useNavigate } from "react-router-dom"; // Funciones de autenticación

const timeToRefreshToken = 4 * 3600; // 4 horas

const useAuthenticated = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [refreshAccessToken] = useLazyQuery(REVALIDATE_QUERY);
  const navigate = useNavigate();
  const client = useApolloClient();

  useEffect(() => {
    const isTokenExpired = async (token: string) => {
      try {
        const decodedToken = decodeJwt(token);

        // Verificar si la propiedad 'exp' está presente y es un número
        if (typeof decodedToken.exp === 'number') {
          const currentTime = Math.floor(Date.now() / 1000); // Obtener el tiempo actual en segundos
          const timeLeft = decodedToken.exp - currentTime;

          // Comparar el tiempo de expiración (exp) con el tiempo actual
          if (decodedToken.exp < currentTime) {
            return true; // El token ha expirado
          }

          if (timeLeft < timeToRefreshToken) { // 30 seconds
            const refreshToken = await (await refreshAccessToken()).refetch();

            if (refreshToken.data?.revalidate) {
              localStorage.setItem('token', refreshToken.data.revalidate.token);
            }
          }
        }
      } catch (error) {
        console.error('Error al decodificar el token:', error);
      }

      return false; // El token no ha expirado o se produjo un error al decodificarlo
    };

    const checkAuthentication = async () => {
      const token = localStorage.getItem('token');
      const user = localStorage.getItem('user');

      if (!token || (await isTokenExpired(token))) {
        localStorage.clear();
        return false;
      }

      if (user) {
        try {
          const userJson = JSON.parse(user);

          if (!userJson.roles.includes('admin')) {
            return false;
          }
        } catch (e) {
          console.error(e);
          return false;
        }
      }

      return true;
    }

    checkAuthentication().then(setIsAuthenticated).catch(console.error);
  }, [client, navigate]);

  return isAuthenticated;
};

export default useAuthenticated;
