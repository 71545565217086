import { gql } from "@apollo/client";

export const GET_CATEGORIES_QUERY = gql`
    query Categories {
        categories {
            id
            name
            createdAt
            parentCategory {
                id
                name
            }
        }
    }
`;

export const CREATE_CATEGORY_MUTATION = gql`
    mutation CreateCategory($createCategoryInput: CreateCategoryInput!) {
        createCategory(createCategoryInput: $createCategoryInput) {
            id
            name
            createdAt
            updatedAt
            parentCategory {
                id
                name
            }
        }
    }
`;
