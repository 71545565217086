import React, { useEffect, useState } from "react";
import { Button, Dropdown, InputGroup } from "@rewind-ui/core";
import {
  MagnifyingGlass,
  SignOut,
  User as UserIcon,
} from "@phosphor-icons/react";
import { User } from "../../types/User";
import AvatarImage from "../../assets/images/avatar.png";
import { useLogout } from "../../hooks/Utils/useLogout";

const Navbar = () => {
  const handleLogout = useLogout();

  const [user, setUser] = useState<Partial<User>>({
    fullName: '',
    email: '',
  });

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    try {
      const user = localStorage.getItem('user');

      if (user) {
        const userToJson = JSON.parse(user);
        setUser(userToJson);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div className="flex items-center justify-between px-12 py-4 border-b-gray-100">
      <div>
        <InputGroup size="lg" tone="transparent" className="bg-white rounded-full w-[600px] max-w-full py-1">
          <InputGroup.Text>
            <MagnifyingGlass weight="light" />
          </InputGroup.Text>
          <InputGroup.Input
            placeholder={'Search here'} type="search" withRing={false}
            className="placeholder:text-sm border-none focus-visible:border-none"
          />
        </InputGroup>
      </div>

      <div className="flex items-center">
        {/* <Dropdown itemColor="gray" tone="light" withChevron={false}> */}
        {/*   <Dropdown.Trigger> */}
        {/*     <Button size="lg" icon tone="transparent" className="bg-white text-neutral-500 rounded-full relative"> */}
        {/*       <Bell size={24} /> */}
        {/*       <Badge size="xs" radius="full" className="bg-red-400 absolute right-3 top-3 w-2.5 h-2.5" /> */}
        {/*     </Button> */}
        {/*   </Dropdown.Trigger> */}
        {/*   <Dropdown.Content className="max-w-[360px] z-[51]"> */}
        {/*     <Dropdown.Label weight="medium" size="md" color="black"> */}
        {/*       Notifications */}
        {/*     </Dropdown.Label> */}
        {/*     <Dropdown.Divider /> */}
        {/*     <Dropdown.Item className="text-left"> */}
        {/*       <div className="flex"> */}
        {/*         <div className="grid grid-cols-1 justify-items-start"> */}
        {/*           <span className="font-medium">New messages in Chats</span> */}
        {/*           <span */}
        {/*             className="font-light text-gray-500" */}
        {/*           >You have new WhatsApp messages waiting to be answered.</span> */}
        {/*         </div> */}
        {/*       </div> */}
        {/*     </Dropdown.Item> */}
        {/*     <Dropdown.Item className="text-left"> */}
        {/*       <div className="flex"> */}
        {/*         <div className="grid grid-cols-1 justify-items-start"> */}
        {/*           <span className="font-medium">Workspace created</span> */}
        {/*           <span className="font-light text-gray-500">Your new workspace is created and ready to be used.</span> */}
        {/*         </div> */}
        {/*       </div> */}
        {/*     </Dropdown.Item> */}
        {/*   </Dropdown.Content> */}
        {/* </Dropdown> */}
        <Dropdown itemColor="gray">
          <Dropdown.Trigger>
            <Button size="md" tone="transparent" withRing={false}>
              <p className="flex flex-col text-right">
                <span className="font-medium">{user.fullName}</span>
                <span className="text-gray text-xs capitalize">Admin</span>
              </p>
              <div className="ml-3">
                <img
                  src={AvatarImage}
                  alt="Avatar"
                  className="w-[50px] rounded-full"
                />
              </div>
            </Button>
          </Dropdown.Trigger>
          <Dropdown.Content className="z-[51]">
            <Dropdown.Label>My account</Dropdown.Label>
            <Dropdown.Divider />
            <Dropdown.Item>
              <UserIcon size={20} className="mr-1.5" />
              My profile
            </Dropdown.Item>
            {/* <Dropdown.Item> */}
            {/*   <CreditCard size={20} className="mr-1.5" /> */}
            {/*   Billing */}
            {/* </Dropdown.Item> */}
            {/* <Dropdown.Item> */}
            {/*   <ShieldCheck size={20} className="mr-1.5" /> */}
            {/*   Security and privacy */}
            {/* </Dropdown.Item> */}
            <Dropdown.Item color="red" onClick={handleLogout}>
              <SignOut size={20} className="mr-1.5" />
              Logout
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown>
      </div>
    </div>
  )
}

export default Navbar;
