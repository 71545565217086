import React, { useEffect, useState } from 'react';
import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View, } from "@react-pdf/renderer";
import Logo from "../assets/images/logo.png";
import Paid from '../assets/images/paid.png';
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { InvoicePayment, InvoiceResponse } from "../types/Invoice";
import { GET_INVOICE_QUERY } from "../hooks/Invoice/useInvoice";
import { User } from "../types/User";

Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf', fontWeight: 600 },
    { src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-black-webfont.ttf', fontWeight: 600 }
  ]
});

const styles = StyleSheet.create({
  page: {
    // backgroundColor: "#d11fb6",
    // color: "white",
  },
  section: {
    fontFamily: 'Roboto',
    margin: 10,
    padding: 10,
    marginBottom: 0,
    paddingBottom: 0
  },
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  table: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableCol: {
    // width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableHeadCol: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    // margin: "auto",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: 9
  },
  tableHeadCell: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 14,
    fontWeight: 600,
    paddingLeft: 5,
    paddingRight: 5,
  }
});

const Invoice = () => {
  const { id } = useParams();
  const [user, setUser] = useState<Partial<User>>({
    fullName: '',
    email: '',
  });

  if (!id) {
    return (<div>Id not found</div>);
  }

  const { data, loading, error } = useQuery<InvoiceResponse>(GET_INVOICE_QUERY, {
    variables: {
      "invoiceId": parseInt(id),
    }
  });

  const getUserInfo = () => {
    try {
      const user = localStorage.getItem('user');

      if (user) {
        const userToJson = JSON.parse(user);
        setUser(userToJson);
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    getUserInfo();
  }, []);

  if (loading || user.fullName === '') {
    return (
      <div>Loading...</div>
    )
  }

  if (error || !data) {
    return (
      <div>Invoice #{id} not found</div>
    )
  }

  const calculateTotal = (subtotal: number, payments: InvoicePayment[]) => {
    return payments.reduce(
      (accumulator, payment) => accumulator - payment.amount,
      subtotal,
    );
  }

  const padWithLeadingZeros = (num: number, totalLength = 8) => {
    return String(num).padStart(totalLength, '0');
  }

  const formatPrice = (price: number) => {
    return price.toLocaleString('es-MX', {
      style: 'decimal',
      currency: 'MXN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    });
  }

  const formatDate = (date: Date) => {
    return (new Date(date)).toDateString().split(' ').slice(1).join(' ')
  }

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Image source={Logo} style={{ width: '148px' }} />
              <View style={{ fontSize: 11, display: 'flex', alignItems: 'flex-end' }}>
                <Text>Invoice for order</Text>
                <Text>#{padWithLeadingZeros(data.invoice.id)}</Text>
                <Text style={{ fontWeight: 600 }}>
                  {formatDate(data.invoice.createdAt)}
                </Text>
              </View>
            </View>
          </View>

          <View style={{
            ...styles.section,
            display: 'flex',
            flexDirection: 'row',
            gap: 50,
            fontSize: 9,
            border: '1px solid black',
            borderRight: 'none',
            borderLeft: 'none',
            paddingBottom: 10
          }}>
            <View style={{ display: 'flex', rowGap: 1.8 }}>
              <Text>Satellite Logistics LLC</Text>
              <Text>DBA Satellite Logistics</Text>
              <Text>3012 ROSS ST</Text>
              <Text>CLOVIS NM 88101</Text>
            </View>
            <View style={{ display: 'flex', rowGap: 1.8 }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ fontWeight: 600 }}>Bill to: </Text>
                <Text>{data.invoice.customerBusiness}</Text>
              </View>
              {data.invoice.customerRFC.length &&
                <Text>RFC: {data.invoice.customerRFC}</Text>
              }
              <Text>{data.invoice.customerStreet}</Text>
              <Text>{data.invoice.customerCity}</Text>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                {data.invoice.customerZip &&
                  <Text>{data.invoice.customerZip} </Text>
                }
                <Text>{data.invoice.customerCountry}</Text>
              </View>
              <Text>{data.invoice.customerPhone}</Text>
            </View>
            {/* Ship to */}
            <View style={{ display: 'flex', rowGap: 1.8 }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ fontWeight: 600 }}>Ship to: </Text>
                <Text>{data.invoice.shipName}</Text>
              </View>
              <Text>{data.invoice.shipBusiness}</Text>
              {data.invoice.shipRFC.length &&
                <Text>RFC: {data.invoice.shipRFC}</Text>
              }
              <Text>{data.invoice.shipStreet}</Text>
              <Text>{data.invoice.shipCity}</Text>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                {data.invoice.shipZip &&
                  <Text>{data.invoice.shipZip} </Text>
                }
                <Text>{data.invoice.shipCountry}</Text>
              </View>
              <Text>{data.invoice.shipPhone}</Text>
            </View>
            {data.invoice.isPaid
              ? <Image source={Paid} style={{ height: '45px', justifyContent: 'center', alignSelf: 'center', transform: 'rotate(-12deg)' }} />
              : null
            }
          </View>
          <View style={{ ...styles.section }}>
            <Text style={{ fontSize: 14, fontWeight: 600 }}>Sales order # {padWithLeadingZeros(data.invoice.id)} - {formatDate(data.invoice.dateOrder)}</Text>
            <View style={{ paddingTop: '10px' }}>
              <View style={{ ...styles.table }}>
                <View style={styles.tableRow}>
                  <View style={styles.tableHeadCol}>
                    <Text style={styles.tableHeadCell}>Products</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={{ ...styles.tableCol, width: '50%' }}>
                    <Text style={{ ...styles.tableCell, margin: 'auto', fontWeight: 600 }}>Name</Text>
                  </View>
                  <View style={{ ...styles.tableCol, width: '20%' }}>
                    <Text style={{ ...styles.tableCell, margin: 'auto', fontWeight: 600 }}>Price</Text>
                  </View>
                  <View style={{ ...styles.tableCol, width: '10%' }}>
                    <Text style={{ ...styles.tableCell, margin: 'auto', fontWeight: 600 }}>Qty</Text>
                  </View>
                  <View style={{ ...styles.tableCol, width: '20%' }}>
                    <Text style={{ ...styles.tableCell, margin: 'auto', fontWeight: 600 }}>Total</Text>
                  </View>
                </View>
                {data.invoice.invoiceProducts.map((product, idx) => (
                  <View style={styles.tableRow} key={`product-${idx}`}>
                    <View style={{ ...styles.tableCol, width: '50%' }}>
                      <View style={styles.tableCell}>
                        <Text>{product.name}</Text>
                        {product.imei &&
                          <Text style={{ fontSize: 7 }}>IMEI: {product.imei}</Text>
                        }
                      </View>
                    </View>
                    <View style={{ ...styles.tableCol, width: '20%' }}>
                      <Text style={{ ...styles.tableCell, textAlign: 'right' }}>
                        ${formatPrice(product.price)}
                      </Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: '10%' }}>
                      <Text style={styles.tableCell}>
                        {product.quantity}
                      </Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: '20%', textAlign: 'right' }}>
                      <Text style={{ ...styles.tableCell }}>
                        ${formatPrice(product.subtotal)}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>

          <View style={{ ...styles.section }}>
            <View style={{ ...styles.table }}>
              <View style={styles.tableRow}>
                <View style={styles.tableHeadCol}>
                  <Text style={styles.tableHeadCell}>Total</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCol, width: '80%' }}>
                  <Text style={{ ...styles.tableCell, fontWeight: 600 }}>Subtotal</Text>
                </View>
                <View style={{ ...styles.tableCol, width: '20%', textAlign: 'right' }}>
                  <Text style={styles.tableCell}>${formatPrice(data.invoice.subtotal)}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCol, width: '80%' }}>
                  <Text style={{ ...styles.tableCell, fontWeight: 600 }}>Shipping ({data.invoice.shippingType} - {data.invoice.shippingPlace})</Text>
                </View>
                <View style={{ ...styles.tableCol, width: '20%', textAlign: 'right' }}>
                  <Text style={styles.tableCell}>${formatPrice(data.invoice.shippingCost)}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCol, width: '80%' }}>
                  <Text style={{ ...styles.tableCell, fontWeight: 600 }}>Order total:</Text>
                </View>
                <View style={{ ...styles.tableCol, width: '20%', textAlign: 'right' }}>
                  <Text style={{ ...styles.tableCell, fontWeight: 600 }}>${formatPrice(calculateTotal(data.invoice.orderTotal, data.invoice.invoicePayments))}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCol, width: '80%' }}>
                  <Text style={{ ...styles.tableCell, fontWeight: 600 }}>Total: Due upon receipt</Text>
                </View>
                <View style={{ ...styles.tableCol, width: '20%', textAlign: 'right' }}>
                  <Text style={{ ...styles.tableCell, fontWeight: 600 }}>${formatPrice(data.invoice.orderTotal)}</Text>
                </View>
              </View>
            </View>
          </View>

          {data.invoice.invoicePayments.length &&
            <View style={{ ...styles.section }}>
              <View style={{ ...styles.table }}>
                <View style={styles.tableRow}>
                  <View style={styles.tableHeadCol}>
                    <Text style={styles.tableHeadCell}>Payments</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={{ ...styles.tableCol, width: '20%' }}>
                    <Text style={{ ...styles.tableCell, fontWeight: 600 }}>Time</Text>
                  </View>
                  <View style={{ ...styles.tableCol, width: '15%' }}>
                    <Text style={{ ...styles.tableCell, fontWeight: 600 }}>Pay type</Text>
                  </View>
                  <View style={{ ...styles.tableCol, width: '20%' }}>
                    <Text style={{ ...styles.tableCell, fontWeight: 600 }}>Transaction ID</Text>
                  </View>
                  <View style={{ ...styles.tableCol, width: '30%' }}>
                    <Text style={{ ...styles.tableCell, fontWeight: 600 }}>Notes</Text>
                  </View>
                  <View style={{ ...styles.tableCol, width: '15%' }}>
                    <Text style={{ ...styles.tableCell, fontWeight: 600 }}>Amount</Text>
                  </View>
                </View>
                {data.invoice.invoicePayments.map((payment, idx) => (
                  <View key={`invoice-payment-${idx}`} style={styles.tableRow}>
                    <View style={{ ...styles.tableCol, width: '20%' }}>
                      <Text style={styles.tableCell}>{new Date(payment.time.toString()).toDateString()}</Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: '15%' }}>
                      <Text style={styles.tableCell}>{payment.paymentType}</Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: '20%' }}>
                      {payment.transactionId &&
                        <Text style={styles.tableCell}>{payment.transactionId}</Text>
                      }
                    </View>
                    <View style={{ ...styles.tableCol, width: '30%' }}>
                      {payment.notes &&
                        <Text style={styles.tableCell}>{payment.notes}</Text>
                      }
                    </View>
                    <View style={{ ...styles.tableCol, width: '15%', textAlign: 'right' }}>
                      <Text style={styles.tableCell}>${formatPrice(payment.amount)}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          }

          <View style={{
            ...styles.section,
            fontSize: 12,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 50
          }}>
            <View style={{ display: 'flex', rowGap: 1.8 }}>
              <Text style={{ fontWeight: 600 }}>Billing Department</Text>
              <Text>+1 (575) 218-9766</Text>
              <Text>admin@globalsdnlogistics.com</Text>
            </View>
            <View style={{ display: 'flex', rowGap: 1.8 }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ fontWeight: 600 }}>Your Sales Rep: </Text>
                <Text>{user.fullName}</Text>
              </View>
              <Text>+1 (575) 218-9766</Text>
              <Text>{user.email}</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
export default Invoice;
