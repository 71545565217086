import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Card, FormControl, Modal } from "@rewind-ui/core";
import { X } from "@phosphor-icons/react";
import { InvoicePaymentCreate } from "../../types/Invoice";
import moment from "moment";

const CreateInvoicePaymentModal = (
  props: {
    editPayment: InvoicePaymentCreate | null,
    editIndex: number | null,
    showModal: boolean,
    onShowModal: (value: boolean) => void,
    onSaveChanges: (payment: InvoicePaymentCreate) => void,
    onSaveEdit: (payment: InvoicePaymentCreate, index: number) => void,
  }) => {
  const { editPayment, editIndex, showModal, onShowModal, onSaveChanges, onSaveEdit } = props;

  const [time, setTime] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [notes, setNotes] = useState('');
  const [amount, setAmount] = useState('');
  const handleTime = (event: ChangeEvent<HTMLDataElement>) => setTime(event.target.value);
  const handlePayType = (event: ChangeEvent<HTMLInputElement>) => setPaymentType(event.target.value);
  const handleTransactionId = (event: ChangeEvent<HTMLInputElement>) => setTransactionId(event.target.value);
  const handleNotes = (event: ChangeEvent<HTMLInputElement>) => setNotes(event.target.value);
  const handleAmount = (event: ChangeEvent<HTMLInputElement>) => setAmount(event.target.value);

  const handleSaveChanges = () => {
    const payment = {
      time: new Date(time),
      paymentType,
      transactionId,
      notes,
      amount: Number(amount),
    }

    if (editPayment !== null) {
      Object.assign(payment, { id: editPayment.id })
    }

    if (editPayment !== null && editIndex !== null) {
      onSaveEdit(payment, editIndex);
      return;
    }

    onSaveChanges(payment);
  }

  useEffect(() => {
    if (showModal) {
      if (editPayment) {
        setTime(moment(editPayment.time).format('YYYY-MM-DD'));
        setPaymentType(editPayment.paymentType);
        setTransactionId(editPayment.transactionId);
        setNotes(editPayment.notes);
        setAmount(editPayment.amount.toString());
        return;
      }

      setTime('');
      setPaymentType('');
      setTransactionId('');
      setNotes('');
      setAmount('');
    }
  }, [showModal]);


  return (
    <>
      <Modal open={showModal} size="md" onClose={() => onShowModal(false)} closeOnEscape={false} overlayCloseOnClick={false} position="center" className="lg:mr-[520px]">
        <Card size="sm" className="w-full">
          <Card.Header
            className="bg-gray-50/50 font-medium"
            actions={
              <Button onClick={() => onShowModal(false)} size="xs" color="gray" icon={true}>
                <X />
              </Button>
            }
          >
            {editPayment ? 'Edit' : 'Create'} payment
          </Card.Header>
          <Card.Body className="space-y-3">
            <FormControl size="sm">
              <FormControl.Label required>Time</FormControl.Label>
              <FormControl.Input type="date" tone="solid" autoComplete="off" value={time} onChange={handleTime} />
            </FormControl>

            <FormControl size="sm">
              <FormControl.Label required>Pay type</FormControl.Label>
              <FormControl.Input type="text" tone="solid" autoComplete="off" value={paymentType} onChange={handlePayType} />
            </FormControl>

            <FormControl size="sm">
              <FormControl.Label>Transaction ID</FormControl.Label>
              <FormControl.Input type="text" tone="solid" autoComplete="off" value={transactionId} onChange={handleTransactionId} />
            </FormControl>

            <FormControl size="sm">
              <FormControl.Label>Notes</FormControl.Label>
              <FormControl.Input type="text" tone="solid" autoComplete="off" value={notes} onChange={handleNotes} />
            </FormControl>

            <FormControl size="sm">
              <FormControl.Label required>Amount</FormControl.Label>
              <FormControl.Input type="number" tone="solid" autoComplete="off" value={amount} onChange={handleAmount} />
            </FormControl>
          </Card.Body>
          <Card.Footer className="bg-gray-50/50 justify-end space-x-2">
            <Button onClick={() => onShowModal(false)} size="sm" tone="transparent" color="gray">
              Cancel
            </Button>
            <Button
              className="font-semibold"
              onClick={handleSaveChanges}
              size="sm"
              color="blue"
              tone="light"
              disabled={!time.length || !paymentType.length || !amount.length}
            >
              {editPayment ? 'Edit' : 'Add'} payment
            </Button>
          </Card.Footer>
        </Card>
      </Modal>
    </>
  );
}

export default CreateInvoicePaymentModal;
