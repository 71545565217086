import { gql } from "@apollo/client";

export const CREATE_INVOICE_MUTATION = gql`
    mutation CreateInvoice($createInvoiceInput: CreateInvoiceInput!) {
        createInvoice(createInvoiceInput: $createInvoiceInput) {
            id
            customerBusiness
            customerCity
            customerCountry
            customerPhone
            customerRFC
            customerState
            customerStreet
            customerZip
            shipBusiness
            shipCity
            shipCountry
            shipName
            shipPhone
            shipRFC
            shipState
            shipStreet
            shipZip
            dateOrder
            isPaid
            orderTotal
            shippingCost
            shippingPlace
            shippingType
            shippingInsurance
            subtotal
            updatedAt
        }
    }
`;

export const CREATE_PRODUCTS_INVOICE_MUTATION = gql`
    mutation CreateInvoiceProduct($createInvoiceProductInput: [CreateInvoiceProductInput!]!) {
        createInvoiceProducts(createInvoiceProductInput: $createInvoiceProductInput) {
            id
            imei
            name
            price
            quantity
            subtotal
            updatedAt
            createdAt
        }
    }
`;

export const CREATE_PAYMENTS_INVOICE_MUTATION = gql`
    mutation CreateInvoicePayments($createInvoicePaymentInput: [CreateInvoicePaymentInput!]!) {
        createInvoicePayments(createInvoicePaymentInput: $createInvoicePaymentInput) {
            id
            time
            paymentType
            transactionId
            notes
            amount
            createdAt
            updatedAt
        }
    }
`;

export const GET_INVOICES_QUERY = gql`
    query Invoices {
        invoices {
            id
            customerBusiness
            customerCity
            customerCountry
            customerPhone
            customerRFC
            customerState
            customerStreet
            customerZip
            dateOrder
            orderTotal
            shipBusiness
            shipCity
            shipCountry
            shipName
            shipPhone
            shipRFC
            shipState
            shipStreet
            shipZip
            shippingCost
            shippingInsurance
            shippingPlace
            shippingType
            subtotal
            createdAt
            isPaid
            invoiceProducts {
                id
                name
                imei
                price
                quantity
                subtotal
                createdAt
            }
            invoicePayments {
                id
                time
                amount
                paymentType
                transactionId
                notes
                createdAt
            }
        }
    }
`;

export const GET_INVOICE_QUERY = gql`
    query Invoice($invoiceId: Int!) {
        invoice(id: $invoiceId) {
            id
            customerBusiness
            customerCity
            customerCountry
            customerPhone
            customerRFC
            customerState
            customerStreet
            customerZip
            dateOrder
            orderTotal
            shipBusiness
            shipCity
            shipCountry
            shipName
            shipPhone
            shipRFC
            shipState
            shipStreet
            shipZip
            shippingCost
            shippingInsurance
            shippingPlace
            shippingType
            isPaid
            subtotal
            createdAt
            invoiceProducts {
                id
                name
                imei
                price
                quantity
                subtotal
                createdAt
            }
            invoicePayments {
                id
                time
                amount
                paymentType
                transactionId
                notes
                createdAt
            }
        }
    }
`;

export const UPDATE_INVOICE_MUTATION = gql`
    mutation UpdateInvoice($updateInvoiceInput: UpdateInvoiceInput!) {
        updateInvoice(updateInvoiceInput: $updateInvoiceInput) {
            id
            customerBusiness
            customerCity
            customerCountry
            customerPhone
            customerRFC
            customerState
            customerStreet
            customerZip
            dateOrder
            orderTotal
            shipBusiness
            shipCity
            shipCountry
            shipName
            shipPhone
            shipRFC
            shipState
            shipStreet
            shipZip
            shippingCost
            shippingPlace
            shippingType
            shippingInsurance
            isPaid
            subtotal
            updatedAt
        }
    }
`;

export const UPDATE_INVOICE_PAYMENTS_MUTATION = gql`
    mutation UpdateInvoicePayments($updateInvoicePaymentInput: [UpdateInvoicePaymentInput!]!) {
        updateInvoicePayments(updateInvoicePaymentInput: $updateInvoicePaymentInput) {
            amount
            notes
            paymentType
            time
            transactionId
        }
}
`;

export const UPDATE_INVOICE_PRODUCTS_MUTATION = gql`
    mutation UpdateInvoiceProducts($updateInvoiceProductInput: [UpdateInvoiceProductInput!]!) {
        updateInvoiceProducts(updateInvoiceProductInput: $updateInvoiceProductInput) {
            imei
            name
            price
            quantity
        }
}
`;


export const REMOVE_INVOICE_MUTATION = gql`
    mutation RemoveInvoice($removeInvoiceId: Int!) {
        removeInvoice(id: $removeInvoiceId)
    }
`;