import React, { useEffect } from "react";
import { Card, Combobox, Drawer, FormControl, Tooltip } from "@rewind-ui/core";
import { ArrowLineRight, Check } from "@phosphor-icons/react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Category, CategoryCreate } from "../../types/Category";

type Inputs = {
  name: string,
  parentCategoryId: string,
};

export const CreateCategoryDrawer = (
  props: {
    showDrawer: boolean,
    onShowDrawer: (value: boolean) => void,
    onSaveChanges: (category: CategoryCreate) => void,
    categories: Category[] | undefined,
  }) => {
  const { showDrawer, onShowDrawer, onSaveChanges, categories } = props;
  const { register, control, handleSubmit, reset, formState: { errors } } = useForm<Inputs>();
  const handleDrawerShow = (value: boolean) => onShowDrawer(value);

  const onSubmit: SubmitHandler<Inputs> = data => {
    onSaveChanges(data);
  };

  useEffect(() => {
    if (showDrawer) {
      reset();
    }
  }, [showDrawer]);

  return (
    <div>
      <Drawer open={showDrawer} onClose={() => handleDrawerShow(false)} className="drawer-floating" closeOnEscape={false} overlayCloseOnClick={false}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="w-full" bordered={false} shadow="none">
            <Card.Header className="bg-gray-50 sticky top-0 z-[50]">
              <div className="flex items-center justify-between w-full">
                <Tooltip label="Close" size="xs">
                  <button onClick={() => handleDrawerShow(false)}>
                    <ArrowLineRight
                      size={20} weight="bold"
                      className="text-gray hover:text-gray-500 transition-colors"
                    />
                  </button>
                </Tooltip>
                <button
                  className="button-primary rounded-md flex items-center gap-x-2 text-sm"
                  disabled={false}
                  type="submit"
                >
                  <Check size={18} weight="bold" />
                  Save changes
                </button>
              </div>
            </Card.Header>

            <Card.Body className="flex flex-1">
              <div className="flex flex-col justify-between">
                <div className="flex flex-col md:w-[30rem] mx-auto">
                  <h3 className="text-md text-gray-700 font-semibold">Create product</h3>

                  <FormControl className="mt-8" validation={errors.name?.type === 'required' ? 'invalid' : 'none'}>
                    <FormControl.Label className="text-sm" required>
                      Category Name:
                    </FormControl.Label>
                    <FormControl.Input
                      required
                      withRing={false}
                      tone="solid"
                      type="text"
                      className="text-sm bg-white focus:bg-white"
                      autoComplete="off"
                      defaultValue={""}
                      {...register("name", { required: true })}
                      aria-invalid={errors.name ? "true" : "false"}
                    />
                    {errors.name?.type === 'required' && <FormControl.Text className="text-red-500 text-xs">Product name is required</FormControl.Text>}
                  </FormControl>

                  <FormControl className="mt-8" validation={errors.parentCategoryId?.type === 'required' ? 'invalid' : 'none'}>
                    <FormControl.Label className="text-sm">
                      Parent Category:
                    </FormControl.Label>
                    <Controller
                      name="parentCategoryId"
                      control={control}
                      render={({ field }) => (
                        <Combobox
                          defaultValue={undefined}
                          value={field.value}
                          onChange={field.onChange}
                        >
                          {categories && categories.length &&
                            categories.map((category, idx) => (
                              <Combobox.Option key={`combobox-category-${idx}`} value={category.id} label={category.name} />
                            ))
                          }
                        </Combobox>
                      )}
                    />
                  </FormControl>
                </div>
              </div>
            </Card.Body>
          </Card>
        </form>
      </Drawer>
    </div>
  )
}
