import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { DotsThree, FilePdf, Pencil, PlusCircle, Trash } from "@phosphor-icons/react";
import { Button, Card, Dropdown, Modal, Table, ToastContainer, useToast } from "@rewind-ui/core";
import {
  CREATE_INVOICE_MUTATION, CREATE_PAYMENTS_INVOICE_MUTATION,
  CREATE_PRODUCTS_INVOICE_MUTATION,
  GET_INVOICES_QUERY,
  REMOVE_INVOICE_MUTATION,
  UPDATE_INVOICE_MUTATION,
  UPDATE_INVOICE_PAYMENTS_MUTATION,
  UPDATE_INVOICE_PRODUCTS_MUTATION
} from "../hooks/Invoice/useInvoice";
import { CreateInvoiceDrawer } from "../components/invoices/CreateInvoiceDrawer";
import {
  Invoice,
  InvoiceCreate,
  InvoiceCreateResponse, InvoicePaymentsCreateResponse,
  InvoicePaymentsUpdateResponse,
  InvoiceProductsCreateResponse,
  InvoiceProductsUpdateResponse,
  InvoiceRemove,
  InvoiceUpdate,
  InvoiceUpdateResponse,
  InvoicesResponse
} from "../types/Invoice";
import { INVOICE_STATUS } from '../consts';
import { useFormatPrice } from "../hooks/Utils/useFormatPrice";

const Orders = () => {
  const toast = useToast();
  const [showModal, setShowModal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [id, setId] = useState<number>(-1);
  const { data, refetch } = useQuery<InvoicesResponse>(GET_INVOICES_QUERY);
  const { NOT_PAYED, PAID } = INVOICE_STATUS;
  // Create Invoice
  const [createInvoiceProductsMutation] = useMutation<InvoiceProductsCreateResponse>(CREATE_PRODUCTS_INVOICE_MUTATION);
  const [createInvoicePaymentsMutation] = useMutation<InvoicePaymentsCreateResponse>(CREATE_PAYMENTS_INVOICE_MUTATION);
  const [createInvoiceMutation] = useMutation<InvoiceCreateResponse>(CREATE_INVOICE_MUTATION);

  // Update Invoice
  const [updateInvoiceMutation] = useMutation<InvoiceUpdateResponse>(UPDATE_INVOICE_MUTATION);
  const [updateInvoiceProductsMutation] = useMutation<InvoiceProductsUpdateResponse>(UPDATE_INVOICE_PRODUCTS_MUTATION);
  const [updateInvoicePaymentsMutation] = useMutation<InvoicePaymentsUpdateResponse>(UPDATE_INVOICE_PAYMENTS_MUTATION);

  // Remove Invoice
  const [removeInvoiceMutation] = useMutation<InvoiceRemove>(REMOVE_INVOICE_MUTATION);
  const [invoiceSelected, setInvoiceSelected] = useState<Invoice | null>(null);

  useEffect(() => {
    refetch();
  }, []);

  const onShowDrawer = () => {
    setShowDrawer(false);
  }

  const handleStatusChange = async (event: React.ChangeEvent<HTMLSelectElement>, invoice: Invoice) => {
    const newStatus = event.target.value === PAID;

    await updateInvoiceMutation({
      variables: {
        updateInvoiceInput: {
          id: invoice.id,
          isPaid: newStatus,
        },
      },
    });

    await refetch();
  };

  const updateInvoice = async (invoice: InvoiceUpdate) => {
    let invoiceProductIds = invoice.invoiceProducts.map((product) => product.id).filter((id) => id !== undefined);
    let invoicePaymentIds = invoice.invoicePayments.map((payment) => payment.id).filter((id) => id !== undefined);

    // Create and Update Products

    for (const product of invoice.invoiceProducts) {
      if (product.id !== '' && product.id !== undefined) {
        await updateInvoiceProductsMutation({
          variables: {
            updateInvoiceProductInput: {
              id: product.id,
              name: product.name,
              imei: product.imei,
              quantity: product.quantity,
              price: product.price,
            },
          },
        });
      }

      if (product.id === '' || product.id === undefined) {
        const data = await createInvoiceProductsMutation({
          variables: {
            createInvoiceProductInput: {
              name: product.name,
              imei: product.imei,
              quantity: product.quantity,
              price: product.price,
            }
          }
        });

        invoiceProductIds = [...invoiceProductIds, data.data?.createInvoiceProducts[0].id];

        await updateInvoiceMutation({
          variables: {
            updateInvoiceInput: {
              id: invoice.id,
              invoiceProductIds: [...invoiceProductIds, data.data?.createInvoiceProducts[0].id],
            }
          }
        })
      }
    }

    // Create and Update Payments
    for (let i = 0; i < invoice.invoicePayments.length; i++) {
      if (invoice.invoicePayments[i].id !== '' && invoice.invoicePayments[i].id !== undefined) {
        await updateInvoicePaymentsMutation({
          variables: {
            updateInvoicePaymentInput: {
              id: invoice.invoicePayments[i].id,
              paymentType: invoice.invoicePayments[i].paymentType,
              transactionId: invoice.invoicePayments[i].transactionId,
              notes: invoice.invoicePayments[i].notes,
              amount: invoice.invoicePayments[i].amount,
            },
          }
        });
      }

      if (invoice.invoicePayments[i].id === '' || invoice.invoicePayments[i].id === undefined) {
        const data = await createInvoicePaymentsMutation({
          variables: {
            createInvoicePaymentInput: {
              time: invoice.invoicePayments[i].time,
              paymentType: invoice.invoicePayments[i].paymentType,
              transactionId: invoice.invoicePayments[i].transactionId,
              notes: invoice.invoicePayments[i].notes,
              amount: invoice.invoicePayments[i].amount,
            }
          }
        });

        invoicePaymentIds = [...invoicePaymentIds, data.data?.createInvoicePayments[0].id];

        await updateInvoiceMutation({
          variables: {
            updateInvoiceInput: {
              id: invoice.id,
              invoicePaymentIds: [...invoicePaymentIds, data.data?.createInvoicePayments[0].id],
            }
          }
        })
      }
    }

    // Update invoice

    await updateInvoiceMutation({
      variables: {
        updateInvoiceInput: {
          id: invoice.id,
          customerBusiness: invoice.customerBusiness,
          customerCity: invoice.customerCity,
          customerCountry: invoice.customerCountry,
          customerPhone: invoice.customerPhone,
          customerRFC: invoice.customerRFC,
          customerState: invoice.customerState,
          customerStreet: invoice.customerStreet,
          customerZip: invoice.customerZip,
          shipName: invoice.shipName,
          shipBusiness: invoice.shipBusiness,
          shipPhone: invoice.shipPhone,
          shipStreet: invoice.shipStreet,
          shipCity: invoice.shipCity,
          shipState: invoice.shipState,
          shipCountry: invoice.shipCountry,
          invoicePaymentIds: invoicePaymentIds,
          invoiceProductIds: invoiceProductIds,
          shipZip: invoice.shipZip,
          shipRFC: invoice.shipRFC,
          dateOrder: invoice.dateOrder,
          shippingType: invoice.shippingType,
          shippingPlace: invoice.shippingPlace,
          shippingCost: invoice.shippingCost,
        },
      },
    });

  }
  const createInvoice = async (invoice: InvoiceCreate) => {
    const createProducts = await createInvoiceProductsMutation({
      variables: {
        createInvoiceProductInput: invoice.invoiceProducts,
      }
    });

    const createPayments = await createInvoicePaymentsMutation({
      variables: {
        createInvoicePaymentInput: invoice.invoicePayments,
      }
    });

    await createInvoiceMutation({
      variables: {
        createInvoiceInput: {
          customerBusiness: invoice.customerBusiness,
          customerCity: invoice.customerCity,
          customerCountry: invoice.customerCountry,
          customerPhone: invoice.customerPhone,
          customerRFC: invoice.customerRFC,
          customerState: invoice.customerState,
          customerStreet: invoice.customerStreet,
          customerZip: invoice.customerZip,
          shipName: invoice.shipName,
          shipBusiness: invoice.shipBusiness,
          shipPhone: invoice.shipPhone,
          shipStreet: invoice.shipStreet,
          shipCity: invoice.shipCity,
          shipState: invoice.shipState,
          shipCountry: invoice.shipCountry,
          shipZip: invoice.shipZip,
          shipRFC: invoice.shipRFC,
          dateOrder: invoice.dateOrder,
          shippingType: invoice.shippingType,
          shippingPlace: invoice.shippingPlace,
          shippingCost: invoice.shippingCost,
          invoiceProductIds: createProducts.data?.createInvoiceProducts.map(
            (product) => product.id
          ),
          invoicePaymentIds: createPayments.data?.createInvoicePayments.map(
            (payment) => payment.id
          ),
        },
      }
    });
  }

  const onSaveChanges = async (invoice: InvoiceCreate | InvoiceUpdate, isEdit: boolean) => {
    try {
      if (isEdit && invoiceSelected) {
        await updateInvoice(invoice as InvoiceUpdate);
        await refetch();
        onShowDrawer();

        toast.add({
          id: 'invoice-updated-success',
          color: 'blue',
          description: 'Invoice updated successfully',
        });
        return;
      }
      await createInvoice(invoice);
      await refetch();
      onShowDrawer();

      toast.add({
        id: 'invoice-created-success',
        color: 'blue',
        description: 'Invoice created successfully',
      });
    } catch (error) {
      console.error(error);

      toast.add({
        id: 'invoice-created-failed',
        color: 'red',
        description: 'An error occurred while trying to create or update the invoice',
      })

    }
  }

  const removeInvoice = async (id: number) => {
    try {
      await removeInvoiceMutation({
        variables: {
          removeInvoiceId: id,
        },
      });
      await refetch();
      setShowModal(!showModal);

    } catch (error) {
      console.error(error);

      toast.add({
        id: 'invoice-removed-failed',
        color: 'red',
        description: 'An error occurred while trying to remove the invoice'
      });
    }
  }

  const padWithLeadingZeros = (num: number, totalLength: number) => {
    return String(num).padStart(totalLength, '0');
  }

  return (
    <div>
      <main className="flex flex-col flex-1 overflow-y-scroll mt-7 px-12">
        <div className="flex items-center justify-between">
          <div>
            <p className="font-semibold text-3xl">Invoices</p>
            <p className="text-gray text-sm mt-2">Invoice generator and list of all invoices</p>
          </div>
          <div>
            <button
              className="button-primary hover:bg-primary/80 focus:bg-primary/80"
              onClick={() => {
                setInvoiceSelected(null);
                setShowDrawer(true);
              }}
            >
              <PlusCircle size={20} weight="fill" />
              <span className="ml-2">Create invoice</span>
            </button>
          </div>
        </div>
        <section className="w-full mt-8 bg-zinc-100 p-4 rounded-2xl outline-none">
          <Table striped={false} hoverable={true} headerColor="white" className="rounded-2xl">
            <Table.Thead>
              <Table.Tr>
                <Table.Th align="left" className="p-[16px]">Invoice #ID</Table.Th>
                <Table.Th align="left" className="p-[16px]">Business</Table.Th>
                <Table.Th align="left" className="p-[16px]">Phone</Table.Th>
                <Table.Th align="left" className="p-[16px]">Date order</Table.Th>
                <Table.Th align="left" className="p-[16px]">Total</Table.Th>
                <Table.Th align="left" className="p-[16px]">Created date</Table.Th>
                <Table.Th align="left" className="p-[16px] pl-10">Status</Table.Th>
                <Table.Th align="right" className="p-[16px]"></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data?.invoices.map((invoice) => (
                <Table.Tr
                  key={`invoice-${invoice.id}`}
                  className="cursor-pointer"
                >
                  <Table.Td>
                    <p className="text-sm font-medium">{padWithLeadingZeros(invoice.id, 8)}</p>
                  </Table.Td>
                  <Table.Td>
                    <p className="text-sm font-medium">{invoice.customerBusiness}</p>
                  </Table.Td>
                  <Table.Td>
                    <p className="text-sm font-medium">{invoice.customerPhone}</p>
                  </Table.Td>
                  <Table.Td>
                    <p className="text-sm font-medium">{(new Date(invoice.dateOrder)).toDateString()}</p>
                  </Table.Td>
                  <Table.Td>
                    <p className="text-sm font-medium">${useFormatPrice(invoice.orderTotal)}</p>
                  </Table.Td>
                  <Table.Td>
                    <p className="text-sm font-medium">{(new Date(invoice.createdAt)).toDateString()}</p>
                  </Table.Td>
                  <Table.Td>
                    <div className='rounded-full border border-gray-200 w-fit px-4 hover:bg-black/5'>
                      <select
                        className='bg-transparent text-sm font-medium py-2 outline-none'
                        onChange={(event) => handleStatusChange(event, invoice)}
                        value={invoice.isPaid ? PAID : NOT_PAYED}>
                        <option value={NOT_PAYED}>Not Payed</option>
                        <option value={PAID}>Paid</option>
                      </select>
                    </div>
                  </Table.Td>
                  <Table.Td>
                    <Dropdown itemColor="gray" withChevron={false} onClick={() => setId(invoice.id)}>
                      <Dropdown.Trigger>
                        <Button size="md" tone="transparent" withRing={false} icon={true} className="hover:bg-black/5">
                          <DotsThree size={24} weight="bold" />
                        </Button>
                      </Dropdown.Trigger>
                      <Dropdown.Content>
                        <Dropdown.Label>Options</Dropdown.Label>
                        <Dropdown.Divider />
                        <Dropdown.Item color="gray">
                          <Link className="flex" to={`/invoice/${invoice.id}`} target="_blank">
                            <FilePdf size={20} className="mr-1.5" />
                            Generate PDF
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item color="gray" onClick={() => {
                          setInvoiceSelected(invoice);
                          setShowDrawer(true)
                        }}>
                          <Pencil size={20} className="mr-1.5" />
                          Edit invoice
                        </Dropdown.Item>
                        <Dropdown.Item color="red" onClick={() => setShowModal(!showModal)}>
                          <Trash size={20} className="mr-1.5" />
                          Delete invoice
                        </Dropdown.Item>
                      </Dropdown.Content>
                    </Dropdown>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </section>
      </main>
      <CreateInvoiceDrawer showDrawer={showDrawer} onShowDrawer={onShowDrawer} onSaveChanges={onSaveChanges} editInvoice={invoiceSelected} />
      <ToastContainer />

      <Modal size='md' position='center' open={showModal} onClose={() => null}>
        <Card className="w-full">
          <Card.Header>
            <h3>Delete invoice</h3>
          </Card.Header>
          <Card.Body>
            <p className='text-sm'>Are you sure you want to delete this invoice?</p>
          </Card.Body>
          <Card.Footer className="bg-gray-50/50 justify-end space-x-2">
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button className="bg-red-400 hover:bg-red-500" onClick={() => removeInvoice(id)}>Delete</Button>
          </Card.Footer>
        </Card>
      </Modal>
    </div >
  );
}

export default Orders;
