import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { CheckFat, X } from "@phosphor-icons/react";
import { Tooltip } from "@rewind-ui/core";

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row' as const,
  flexWrap: 'wrap' as const,
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box' as const
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


const Dropzone = (props: { images: File[], onChange: (images: File[]) => void, onChangeCover: (index: number | null) => void }) => {
  const { images, onChange, onChangeCover } = props;
  // const [images, setImages] = useState<File[]>([]);
  const [coverIndex, setCoverIndex] = useState<number | null>(null);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    accept: {
      'image/*': []
    },
    onDrop: acceptedFiles => {
      // setImages((images: File[]) => [...images, ...acceptedFiles.map(file => Object.assign(file, {
      //   preview: URL.createObjectURL(file)
      // }))]);

      onChange([...images, ...acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))])
    }
  });

  const onRemove = (idx: number) => {
    // setImages((images: File[]) => images.filter((image, index) => index !== idx)
    //   .map((file) => Object.assign(file, {
    //     preview: URL.createObjectURL(file)
    //   })));

    onChange(images.filter((image, index) => index !== idx)
      .map((file) => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
  }

  const thumbs = images.map((file: any, idx: number) => (
    <div style={thumb} key={`${file.name}-${idx}`}>
      <div
        className="group"
        style={{ ...thumbInner, position: 'relative', width: '100%' }}
      >
        <div style={{ zIndex: 10, width: '100%' }}>
          <span
            className="hover:bg-white/30 text-sm rounded-full p-1 cursor-pointer text-red-500"
            style={{ position: 'absolute', right: '5px', top: '5px' }}
            onClick={() => onRemove(idx)}
          >
            <X />
          </span>
          <div>
            <Tooltip label="Set as cover" size="xs">
              <CheckFat
                weight="duotone"
                className={`text-green-600 cursor-pointer rounded-full p-1 ${coverIndex === idx ? 'bg-white/30' : 'invisible group-hover:visible hover:bg-white/30'}`}
                fontSize={32}
                style={{ position: 'absolute', right: '32%', top: '33%' }}
                onClick={() => setCoverIndex(idx)}
              />
            </Tooltip>
          </div>
        </div>
        <img
          src={file.preview}
          className="object-cover"
          style={{ ...img, position: 'absolute', width: '100px', height: '100px' }}
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
          alt="" />
      </div>
    </div>
  ));

  useEffect(() => {
    return () => images.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  // useEffect(() => {
  //   onChange(images);
  // }, [images]);

  useEffect(() => {
    onChangeCover(coverIndex);
  }, [coverIndex]);

  return (
    <section className="container">
      <div {...getRootProps({ style: baseStyle })}>
        <input {...getInputProps()} />
        <p>Drag and drop images here, or click to select files</p>
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    </section>
  );
}

export default Dropzone;
