import React, { useState } from 'react';
import { Avatar, Badge, Tabs } from "@rewind-ui/core";
import { Archive, Coins, Package, Users } from "@phosphor-icons/react";
import NewContactsChart from "../components/dashboard/NewContactsChart";

const Dashboard = () => {
  const [newContactsGraphType, setNewContactsGraphType] = useState('monthly');

  return (
    <div>
      <div className="flex flex-col px-12 pt-7">
        <div>
          <p className="font-semibold text-3xl">Dashboard</p>
          <p className="text-gray text-sm mt-2">Welcome to SDN Logistics, from now on manage your company
            efficiently.</p>
        </div>

        <main>
          <div className="flex gap-x-8 my-7">
            <section className="flex flex-col gap-8 w-7/12">
              <div className="flex flex-col gap-8">
                <div className="flex gap-8">
                  <div
                    className="flex justify-between items-center w-1/2 bg-white p-8 rounded-2xl shadow-md
                    shadow-gray-100"
                  >
                    <div>
                      <p className="font-semibold text-3xl">0</p>
                      <p>New Orders</p>
                      {/* <p className="text-gray text-xs"><span*/}
                      {/*  className="text-red-500 font-semibold"*/}
                      {/* >-2%</span> than last week</p>*/}
                    </div>
                    <div className="bg-[#FDF2EE] p-4 rounded-xl">
                      <Archive size={34} weight="fill" color="#F56F4C" />
                    </div>
                  </div>
                  <div
                    className="flex justify-between items-center w-1/2 bg-white p-8 rounded-2xl shadow-md
                    shadow-gray-100"
                  >
                    <div>
                      <p className="font-semibold text-3xl">0</p>
                      <p>Total Products</p>
                      {/* <p className="text-gray text-xs"><span*/}
                      {/*  className="text-green-500 font-semibold"*/}
                      {/* >+0.5%</span> than last week</p>*/}
                    </div>
                    <div className="bg-[#E4FBE7] p-4 rounded-xl">
                      <Package size={34} weight="fill" color="#38E25D" />
                    </div>
                  </div>
                </div>
                <div className="flex gap-8">
                  <div
                    className="flex justify-between items-center w-1/2 bg-white p-8 rounded-2xl shadow-md
                    shadow-gray-100"
                  >
                    <div>
                      <p className="font-semibold text-3xl">0</p>
                      <p>Total Users</p>
                      {/* <p className="text-gray text-xs"><span*/}
                      {/*  className="text-green-500 font-semibold"*/}
                      {/* >+1%</span> than last week</p>*/}
                    </div>
                    <div className="bg-[#E7F3FF] p-4 rounded-xl">
                      <Users size={34} weight="fill" color="#4BA4F4" />
                    </div>
                  </div>
                  <div
                    className="flex justify-between items-center w-1/2 bg-white p-8 rounded-2xl shadow-md
                    shadow-gray-100"
                  >
                    <div>
                      <p className="font-semibold text-3xl">$0</p>
                      <p>Total Revenue</p>
                      {/* <p className="text-gray text-xs"><span*/}
                      {/*  className="text-green-500 font-semibold"*/}
                      {/* >+0.3%</span> than last week</p>*/}
                    </div>
                    <div className="bg-[#F4E6FF] p-4 rounded-xl">
                      <Coins size={34} weight="fill" color="#AD4DF5" />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="bg-white p-8 rounded-2xl shadow-md shadow-gray-100"> */}
              {/*   <div className="flex justify-between items-center"> */}
              {/*     <p className="font-medium">Sales Revenue</p> */}
              {/*     <Tabs defaultTab={salesRevenueGraphType} color="yellow" size="sm"> */}
              {/*       <Tabs.List className="p-0 m-0 font-medium"> */}
              {/*         <Tabs.Tab */}
              {/*           anchor="monthly" */}
              {/*           className={`border-b-4 hover:border-b-yellow-400 hover:text-yellow-400 w-20 ${ */}
              {/*             salesRevenueGraphType === 'monthly' */}
              {/*               ? 'text-yellow-400 border-b-yellow-400' */}
              {/*               : 'border-b-gray-200'}`} */}
              {/*           onClick={() => setSalesRevenueGraphType('monthly')} */}
              {/*         > */}
              {/*           Monthly */}
              {/*         </Tabs.Tab> */}
              {/*         <Tabs.Tab */}
              {/*           anchor="monthly" */}
              {/*           className={`border-b-4 hover:border-b-yellow-400 hover:text-yellow-400 w-20 ${ */}
              {/*             salesRevenueGraphType === 'daily' */}
              {/*               ? 'text-yellow-400 border-b-yellow-400' */}
              {/*               : 'border-b-gray-200'}`} */}
              {/*           onClick={() => setSalesRevenueGraphType('daily')} */}
              {/*         > */}
              {/*           Daily */}
              {/*         </Tabs.Tab> */}
              {/*         <Tabs.Tab */}
              {/*           anchor="monthly" */}
              {/*           className={`border-b-4 hover:border-b-yellow-400 hover:text-yellow-400 w-20 ${ */}
              {/*             salesRevenueGraphType === 'today' */}
              {/*               ? 'text-yellow-400 border-b-yellow-400' */}
              {/*               : 'border-b-gray-200'}`} */}
              {/*           onClick={() => setSalesRevenueGraphType('today')} */}
              {/*         > */}
              {/*           Today */}
              {/*         </Tabs.Tab> */}
              {/*       </Tabs.List> */}
              {/*     </Tabs> */}
              {/*   </div> */}
              {/*   <div className="mt-4"> */}
              {/*     <SalesRevenueChart /> */}
              {/*   </div> */}
              {/* </div> */}

              {/* <div className="bg-white p-8 rounded-2xl shadow-md shadow-gray-100">*/}
              {/*  <div className="flex justify-between items-center">*/}
              {/*    <p className="font-medium">New Orders</p>*/}
              {/*    <Tabs defaultTab={newContactsGraphType} color="blue" size="sm">*/}
              {/*      <Tabs.List className="p-0 m-0 font-medium">*/}
              {/*        <Tabs.Tab*/}
              {/*          anchor="monthly"*/}
              {/*          className={`border-b-4 hover:border-b-blue-400 hover:text-blue-400 w-20 ${*/}
              {/*            newContactsGraphType === 'monthly'*/}
              {/*              ? 'text-blue-400 border-b-blue-400'*/}
              {/*              : 'border-b-gray-200'}`}*/}
              {/*          onClick={() => setNewContactsGraphType('monthly')}*/}
              {/*        >*/}
              {/*          Monthly*/}
              {/*        </Tabs.Tab>*/}
              {/*        /!* <Tabs.Tab *!/*/}
              {/*        /!*   anchor="monthly" *!/*/}
              {/*        /!*   className={`border-b-4 hover:border-b-blue-400 hover:text-blue-400 w-20 ${ *!/*/}
              {/*        /!*     newContactsGraphType === 'daily' *!/*/}
              {/*        /!*       ? 'text-blue-400 border-b-blue-400' *!/*/}
              {/*        /!*       : 'border-b-gray-200'}`} *!/*/}
              {/*        /!*   onClick={() => setNewContactsGraphType('daily')} *!/*/}
              {/*        /!* > *!/*/}
              {/*        /!*   Daily *!/*/}
              {/*        /!* </Tabs.Tab> *!/*/}
              {/*        /!* <Tabs.Tab *!/*/}
              {/*        /!*   anchor="monthly" *!/*/}
              {/*        /!*   className={`border-b-4 hover:border-b-blue-400 hover:text-blue-400 w-20 ${ *!/*/}
              {/*        /!*     newContactsGraphType === 'today' *!/*/}
              {/*        /!*       ? 'text-blue-400 border-b-blue-400' *!/*/}
              {/*        /!*       : 'border-b-gray-200'}`} *!/*/}
              {/*        /!*   onClick={() => setNewContactsGraphType('today')} *!/*/}
              {/*        /!* > *!/*/}
              {/*        /!*   Today *!/*/}
              {/*        /!* </Tabs.Tab> *!/*/}
              {/*      </Tabs.List>*/}
              {/*    </Tabs>*/}
              {/*  </div>*/}
              {/*  <div className="mt-4">*/}
              {/*    <NewContactsChart />*/}
              {/*  </div>*/}
              {/* </div>*/}

            </section>
            <section className="w-4/12">
              <div className="bg-white rounded-2xl p-8 shadow-md shadow-gray-100">
                <div className="flex justify-between items-center">
                  <p className="font-semibold text-xl">Last orders</p>
                  <div className="border border-gray-300 pr-3 rounded-3xl">
                    <select name="" id="" className="py-2 pl-3 text-sm text-gray rounded-3xl outline-none">
                      <option value="newest">Newest</option>
                      <option value="upcoming">Upcoming</option>
                    </select>
                  </div>
                </div>

                <ul className="mt-4 flex flex-col divide-y divide-gray-100">
                  <li className="py-8">
                    <p className="font-medium">Product name</p>
                    <div className="mt-3 flex justify-between items-center">
                      <div className="flex items-center gap-x-4">
                        <Avatar initials="AG" color="blue" size="sm" />
                        <div>
                          <p className="font-medium text-sm">Name Lastname</p>
                          <p className="text-gray text-xs">2m ago</p>
                        </div>
                      </div>
                      <div>
                        <Badge
                          radius="full"
                          size="xs"
                          className="font-normal text-[11px] bg-red-100 text-red-400 px-2"
                        >
                          Cancelled
                        </Badge>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
